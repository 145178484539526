import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IonImg } from '@ionic/react';
import JoinCodeForm from './JoinCodeForm';

const StyledLogoImg = styled(IonImg)`
    position: fixed;
    bottom: 10px;
    right: -10%;
    height: 50%;
    width: 80%;
`;


const JoinWindow = props  => {
    useEffect(() => {

    }, []);

  return (
    <div>
        <JoinCodeForm getContest={props.getContest} contest={props.contest}/>
        <StyledLogoImg src="/assets/jazzdaq_logo_fullcolor.svg"/>
    </div>
  );
};

export default JoinWindow;
