import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent } from '@ionic/react';

import Header from './../Header';
import BottomNavTabs from '../BottomNavTabs';
import LedgerPage from './LedgerPage';


const StyledIonContent = styled(IonContent)`

`;

const StyledPage = styled.div`
  overflow: scroll;
  height: calc(100% - 148px);
  -webkit-overflow-scrolling: auto;
`;




const WalletPage = props  => {
    useEffect(() => {

    }, []);
  return (
    <StyledIonContent>
        <Header menu={true} title="My Wallet"/>
        <StyledPage>
            <LedgerPage />
        </StyledPage>
        <BottomNavTabs selected={1} />
    </StyledIonContent>
  );
};

export default WalletPage;
