import {
    SET_SEARCH_CONTEST_RESULTS,
    SET_SEARCH_MUSIC_RESULTS,
  } from './actions';
  
const INITIAL_STATE = {
    results: 0
};

// @ts-ignore
export function contestSearchResults(state = INITIAL_STATE, { type, payload = [] } = {}) {
    switch (type) {
        case SET_SEARCH_CONTEST_RESULTS: 
            return {results: payload.length, hits: payload};
        default: 
            return state;
    }
};


// @ts-ignore
export function musicSearchResults(state = [], { type, payload = {} } = {}) {
    switch (type) {
        case SET_SEARCH_MUSIC_RESULTS: 
            return payload;
        default: 
            return state;
    }
}

  