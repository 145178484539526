import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getContestData } from './../ducks/contests/actions';
import { getFeaturedData } from './../ducks/features/actions';

import HomePage from './../components/Home/HomePage';

interface HomeProps {
  getContests: Function
  getFeatured: Function
  getUser: Function
  contests: any
  featured: any
  userData: any
}

const HomeContainer: React.FC<HomeProps> = (state, props) => {
  useEffect(() => {
    state.getContests();
    state.getFeatured();
    state.getUser();
    return () => {
    }
  }, [state.userData?.firstName])

    return (<HomePage contests={state.contests} featured={state.featured} userData={state.userData}/>)
};

const mapPropsToDispatch = (dispatch, props) => {
  return {
    getContests: () => dispatch(getContestData()),
    getFeatured: () => dispatch(getFeaturedData()),
    getUser: () => dispatch({type: 'GET_USER_DATA', data: {history: props.history, home: true}})
  }
};




export default connect((state, props) => {
  const newProps = {
    contests: state.contests,
    featured: state.featured,
    userData: state.userData
  };
  return newProps;
}, mapPropsToDispatch)(HomeContainer);
