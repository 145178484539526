//@ts-nocheck
import {
    SET_ERROR_MESSAGE,
    SHOW_ERROR
} from './actions';
  

// @ts-ignore
export function errorMessage(state = [], { type, payload = [] } = {}) {
    switch (type) {
        case SET_ERROR_MESSAGE:
            return payload;
        default: 
            return state;
    } 
};

// @ts-ignore
export function showErrorMessage(state = [], { type, payload = [] } = {}) {
    switch (type) {
        case SHOW_ERROR:
            return payload;
        default: 
            return state;
    }
}