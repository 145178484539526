import { createSelector } from 'reselect';



export const getCreateContestResultsState = ({ createContestResults = {} }) => createContestResults;

export const getCreateContestResults = createSelector(
    [getCreateContestResultsState],
    results => results
);

export const getContestData = ({ contest = {} }) => contest;

export const getContest = createSelector(
    [getContestData],
    results => results
);


export const getJoinContestResultsData = ({ joinContestResults = null}) => joinContestResults;

export const getJoinContestResults = createSelector(
    [getJoinContestResultsData],
    results => results
);

export const getAddPickData = ({ artistId, artistName, trackId, trackName, artworkUrl60, contestId }) => ({ 
    artistId, 
    artistName, 
    trackId, 
    trackName, 
    artworkUrl60, 
    contestId });

export const getAddPick = createSelector(
    [getAddPickData],
    results => results
);


export const getTrackDataState = ({ trackData = null }) => trackData;

export const getTrackDataResults = createSelector(
    [getTrackDataState],
    results => results
);


export const getPickDataState = ({ pickData = null }) => pickData;

export const getPickDataResults = createSelector(
    [getPickDataState],
    results => results
);

export const getContestTracksState = ({ contestTrackData = null }) => contestTrackData;

export const getContestTracksResults = createSelector(
    [getContestTracksState],
    results => results
);