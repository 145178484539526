// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { get } from 'lodash';
import { 
    getContest, 
    joinContest,
    addPickToCollection,
    removePickFromCollection,
    getTrackData,
    setTrackData,
    getPickData,
    getContestTracks as getContestTracksAction,
    removeFromContest as removeFromContestAction,
 } from './../ducks/contests/actions';

 import {
     searchMusic
 } from './../ducks/search/actions';

import {
    getMusicSearchResults as getMusicSearchResultsSelector
} from './../ducks/search/selectors';
import { 
    getContest as getContestSelector,
    getJoinContestResults as getJoinContestResultsSelector,
    getTrackDataResults as getTrackDataResultsSelector,
    getPickDataResults as getPickDataResultsSelector,
    getContestTracksResults as getContestTracksResultsSelector,
} from './../ducks/contests/selectors';
import {
    getUserStateSelector 
} from './../ducks/auth/selectors';


import ContestPage from './../components/Contest/ContestPage';

import {
    IonAlert
} from '@ionic/react';

const ContestContainer: React.FC = (state, props) => {
    const { 
    // @ts-ignore
        contestId, 
    // @ts-ignore
        page, 
    // @ts-ignore
        contest, 
    // @ts-ignore
        changePage, 
    // @ts-ignore
        joinContest,
    // @ts-ignore
        joinContestResults,
    // @ts-ignore
        searchMusic,
    // @ts-ignore
        musicSearchResults,
    // @ts-ignore
        addPickToCollection,
    // @ts-ignore
        removePickFromCollection,
    // @ts-ignore
        userData,
    // @ts-ignore
        trackData,
    // @ts-ignore
        getTrackData,
    // @ts-ignore
        clearTrackData,
        getPickData,
        pickData,
        userId,
        getContestTracks,
        contestTracks,
        removeFromContest
    } = state;
    useEffect(() => {
        // @ts-ignore
        state.getContest({formData: state.contestId});
    }, []);
  // @ts-ignore
  if (contest.error) {
    return (    
    <IonAlert
        isOpen={true}
        onDidDismiss={() => {
            // @ts-ignore
            state.history.push('/');
        }}
        header={'Not Fund'}
        message={`Contest id ${contestId} not found.`}
        buttons={['Return Home']}
      />)
  }else{
    return (<ContestPage 
        changePage={changePage}
        contest={contest}
        page={page}
        joinContest={joinContest}
        joinContestResults={joinContestResults}
        searchMusic={searchMusic}
        musicSearchResults={musicSearchResults}
        addPickToCollection={addPickToCollection}
        removePickFromCollection={removePickFromCollection}
        userData={userData}
        trackData={trackData}
        getTrackData={getTrackData}
        clearTrackData={clearTrackData}
        pickData={pickData}
        getPickData={getPickData}
        contestId={contestId}
        userId={userId}
        contestTracks={contestTracks}
        getContestTracks={getContestTracks}
        removeFromContest={removeFromContest}
    />);
  }
};

const changePage = ( page, { history }) => {
    history.push({
        search: `?p=${page}`
    });
}


const mapPropsToDispatch = (dispatch, props) => ({
    getContest: id => dispatch(getContest(id)),
    changePage: page => changePage(page, props),
    joinContest: (id, email) => dispatch(joinContest(id, email)),
    searchMusic: term => dispatch(searchMusic(term)),
    addPickToCollection: pick => dispatch(addPickToCollection(pick)),
    removePickFromCollection: pick => dispatch(removePickFromCollection(pick)),
    getTrackData: id => dispatch(getTrackData(id)),
    clearTrackData: () => dispatch(setTrackData(null)),
    getPickData: data => dispatch(getPickData(data)),
    getContestTracks: id => dispatch(getContestTracksAction(id)),
    removeFromContest: (contestId, userId) => dispatch(removeFromContestAction(contestId, userId)),
});


const mapPropsToState = (state, props) => {
    return {
        contestId: get(props, 'match.params.contestId'),
        userId: get(queryString.parse(get(props, 'location.search')), 'userId'),
        page: get(queryString.parse(get(props, 'location.search')), 'p') || 0,
        contest: getContestSelector(state),
        joinContestResults: getJoinContestResultsSelector(state),
        musicSearchResults: getMusicSearchResultsSelector(state),
        userData: getUserStateSelector(state),
        trackData: getTrackDataResultsSelector(state),
        pickData: getPickDataResultsSelector(state),
        contestTracks: getContestTracksResultsSelector(state),
    };
};


export default connect(
  mapPropsToState, 
  mapPropsToDispatch
)(ContestContainer);
