import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
  IonContent,
} from '@ionic/react';

import Header from './../Header';
import BottomNavTabs from '../BottomNavTabs';
import JoinWindow from './JoinWindow';


const StyledIonContent = styled(IonContent)`

`;



const AttendPage = props  => {

  useEffect(() => {
  })


  return (
    <StyledIonContent>
        <Header menu={true} title="Attend"/>
        <JoinWindow getContest={props.joinContest} contest={props.contest}/>
        <BottomNavTabs selected={4}/>
    </StyledIonContent>
  );
};

export default AttendPage;
