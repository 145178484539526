import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent, IonItem, IonCardContent } from '@ionic/react';

import SearchComponent from '../Shared/SearchComponent';




const WatchlistSearch = props  => {
    useEffect(() => {

    }, []);
  return (
    <SearchComponent
        search={props.searchMusic}
        results={props.musicResults}
        add={e=>props.addToWatchlist(e.trackId)}
        showAdd={true}
        addLabel="Watch"
        style={{width: '100%'}}
    />
  );
};

export default WatchlistSearch;
