import React, { useState } from 'react';
import Youtube from 'react-youtube-embed';
import Styled from 'styled-components';
import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonList,
    IonItem,
    IonButton,
    IonInput,
    IonLabel,
} from '@ionic/react';



const StyledYoutube = Styled(Youtube)`
    margin-top: 10px;
    float: left;
    width: 100%;
    display: block;
`;

interface SourcePageResults {
    id: string
    results: any
    setAdminYoutubeSource: Function
}


const SourcePage: React.FC<SourcePageResults> = props => {
    const { 
        id,
        results,
        setAdminYoutubeSource,
    } = props;
    const [ytUrl, setYtUrl] = useState('')

    function handleYoutubeLink() {
        const url = ytUrl;
        const ytId = url.split('=')[1];
        setAdminYoutubeSource({id, youtube_id: ytId});
        setTimeout(() => {
            window.location.href='/admin?p=source';
        }, 1000);
    }

    return <IonCard>
        <IonCardContent>
            Choose the most accurate video
            <IonItem>
                <IonLabel position="floating">Youtube Link</IonLabel>
                <IonInput type="url" onIonChange={e => setYtUrl(e.detail.value!)}></IonInput>
                <IonButton onClick={handleYoutubeLink}>Save</IonButton>
            </IonItem>
                {results.map(vid => {
                    if (vid.type === 'video') {
                        return <><IonItem>
                            <StyledYoutube id={vid.id} />
                        </IonItem>
                        <IonItem>
                            <IonButton expand="block" style={{width: '100%' }} onClick={() => {
                                setAdminYoutubeSource({id, youtube_id: vid.id});
                                setTimeout(() => {
                                    window.location.href='/admin?p=source';
                                }, 1000);
                            }}>This one</IonButton>
                        </IonItem>
                        </>
                    }

                })}
        </IonCardContent>
    </IonCard>
}


export default SourcePage;