import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    IonGrid, 
    IonRow, 
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonButton,
    IonToast
  } from '@ionic/react';





  
const StyledTitle = styled(IonCol)`
  text-align: center;
  font-weight: bold;
  font-family: 'Clarendon-Serial';
  font-size: 1.2em;
  margin-top:10%;
  color: #0f2070;
`;

const TitleStyle = styled.div`
    margin-top: 40px;
    padding: 0px;
    font-weight: bold;
    font-family: 'Clarendon-Serial'
`;


const StyledIonGrid = styled(IonGrid)`
    overflow: scroll;
    height: calc(100% - 155px);
    -webkit-overflow-scrolling: auto;

`;

const valid = ({ name, startDate }) => {
    let isValid = true;
    let notValidMessage = '';
    if (name.length < 5) {
        isValid = false;
        notValidMessage += 'Contest name must be at least 5 characters.';
    }

    if (!startDate.length) {
        isValid = false;
        notValidMessage += 'Contest must have a start date';
    }

    return { valid: isValid, message: notValidMessage };

};


const CreateContestForm = (props)  => {

    const [name, setName] = useState("");
    const [duration, setDuration] = useState(5);
    const [genre, setGenre] = useState("all");
    const [startDate, setStartDate] = useState("");
    const [publicContest, setPublicContest] = useState(true)
    const [songs, setSongs] = useState(3);
    const [fee, setFee] = useState(10);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        if (valid({name, startDate}).valid) {
            setSubmitDisabled(false);
        }else{
            setSubmitDisabled(true);
        }
    })


    const onClickHandle = () => {
        const contestObj = {
            name,
            duration,
            genre,
            public: publicContest,
            pickCount: songs,
            fee,
            startDate
        };
        props.createContest(contestObj);
    };



    return (
    <StyledIonGrid>
        <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonLabel position="floating">Your Contest Name</IonLabel>
                    <IonInput value={name} onIonChange={e => setName(e.detail.value!)} />
                </IonItem>
            </IonCol>
        </IonRow>

        <IonRow>
            <StyledTitle offset="1" size="10">Contest Details</StyledTitle>
        </IonRow>

        <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonDatetime 
                        displayFormat="MMM/DD/YYYY" 
                        placeholder="Select Start Date"
                        value={startDate}
                        onIonChange={e => setStartDate(e.detail.value!)}
                        />
                </IonItem>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonLabel position="floating">Duration</IonLabel>
                    <IonSelect onIonChange={e => setDuration(e.detail.value!)} value={duration}>
                        <IonSelectOption value={2}>2 weeks</IonSelectOption>
                        <IonSelectOption value={3}>3 weeks</IonSelectOption>
                        <IonSelectOption value={4}>4 weeks</IonSelectOption>
                        <IonSelectOption value={5}>5 weeks</IonSelectOption>
                        <IonSelectOption value={6}>6 weeks</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonCol>
        </IonRow>


        <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonLabel position="floating">All Genres</IonLabel>
                    <IonSelect onIonChange={e => setGenre(e.detail.value!)} value={genre} disabled={true}>
                        <IonSelectOption value={"all"}>All Genre</IonSelectOption>
                        <IonSelectOption value={"hip-hop"}>Hip Hop</IonSelectOption>
                        <IonSelectOption value={"country"}>Country</IonSelectOption>
                        <IonSelectOption value={"indie"}>Indie</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonLabel>Public</IonLabel>
                    <IonToggle 
                        color="primary" 
                        checked={publicContest} 
                        disabled={true}
                        onIonChange={e => setPublicContest(e.detail.checked!)} ></IonToggle>
                </IonItem>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonLabel position="floating">Number of Songs</IonLabel>
                    <IonSelect 
                        onIonChange={e => setSongs(e.detail.value!)} 
                        value={songs} 
                    >
                        <IonSelectOption value={3}>3 Songs</IonSelectOption>
                        <IonSelectOption value={5}>5 Songs</IonSelectOption>
                        <IonSelectOption value={10}>10 Songs</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonCol>
        </IonRow>

        {/* <IonRow>
            <IonCol offset="1" size="10">
                <IonItem>
                    <IonLabel position="floating">Entry Fee</IonLabel>
                    <IonSelect 
                        onIonChange={e => setFee(e.detail.value!)} 
                        value={fee} 
                    >
                        <IonSelectOption value={5}>$5</IonSelectOption>
                        <IonSelectOption value={10}>$10</IonSelectOption>
                        <IonSelectOption value={25}>$25</IonSelectOption>
                        <IonSelectOption value={50}>$50</IonSelectOption>
                        <IonSelectOption value={75}>$75</IonSelectOption>
                        <IonSelectOption value={100}>$100</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonCol>
        </IonRow> */}

        <IonRow>
            <IonCol offset="1" size="11">
                <IonItem>
                    <IonButton 
                        color="primary" 
                        slot="start" 
                        disabled={submitDisabled}
                        style={{width: '100%', height: '50px'}}
                        onClick={onClickHandle}
                    >Start Contest</IonButton>
                </IonItem>
            </IonCol>
        </IonRow>

        <IonToast
            color="danger"
            isOpen={showError}
            onDidDismiss={() => setShowError(false)}
            message={errorMessage}
            duration={2000}
        />
    </StyledIonGrid>

  );
};

export default CreateContestForm;
