import React from 'react';
import {
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonImg,
    IonButton,
} from '@ionic/react';

import SourcePage from './SourcePage';

interface SourceWindowProps {
    trackSourceData: any
    track: string
    trackYoutubeData: any
    setAdminYoutubeSource: Function
}

const SourceWindow: React.FC<SourceWindowProps> = props => {
    const { 
        trackSourceData, 
        track, 
        trackYoutubeData, 
        setAdminYoutubeSource 
    } = props;

    if (!track) {
        return  <IonItem><IonList>
            {trackSourceData.map(item => {
                return <IonItem key={item.id} href={`admin?p=source&track=${item.id}`}>
                    <IonAvatar slot="start">
                        <IonImg src={item.artwork_url60} />
                    </IonAvatar>
                    <IonLabel style={{marginLeft: '30px'}}>{item.artist_name} - {item.track_name}</IonLabel>
                </IonItem>
            })}
        </IonList></IonItem>
    }else{
        return <SourcePage 
            results={trackYoutubeData} 
            setAdminYoutubeSource={setAdminYoutubeSource} 
            id={track}
        />
    }
};


export default SourceWindow;