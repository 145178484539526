import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
    IonRow,
    IonCol
} from '@ionic/react';


const getDays = momentDate => {
    const now = moment(Date.now());
    return momentDate.diff(now, 'days');
}

const getHours = momentDate => {
    const now = moment(Date.now());
    const days: number = getDays(momentDate);
    const hours: number =  momentDate.diff(now, 'hours');
    return hours - days * 24;
}

const getMinutes = momentDate => {
    const now = moment(Date.now());
    const days = getDays(momentDate);
    const hours = getHours(momentDate);
    const minutes = momentDate.diff(now, 'minutes');
    return minutes - (days*24*60) - hours*60
};

const getSeconds = momentDate => {
    const now = moment(Date.now());
    const days = getDays(momentDate);
    const hours = getHours(momentDate);
    const minutes = getMinutes(momentDate);
    const seconds = momentDate.diff(now, 'seconds');
    return seconds - days*24*60*60 - hours*60*60 - minutes*60 
};


const StyledIonCol = styled(IonCol)`
    margin-top: 10%;
    height: 60px;
    text-align: center;
    padding-top: 10px;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Domaine-Regular';
    background: #088C87;
    color: white;
`;

const StyledTitleIonCol = styled(IonCol)`
    text-align: center;
    font-weight: bold;
    font-family: 'Domaine-Regular';
`;

interface ContestCountdownProps {
    endDate: String
}

const ContestCountdown: React.FC<ContestCountdownProps> = props => {
    const { endDate } = props;
    const [timer, setTimer] = useState(0);

    const momentDate = moment(endDate.toString());
    const days = getDays(momentDate),
          hours = getHours(momentDate),
          minutes = getMinutes(momentDate),
          seconds = getSeconds(momentDate);

    useEffect(() => {
        const interval = setInterval(() => setTimer(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [])

    // return <IonRow style={{marginTop: '30px'}}>
    //     <IonCol offset="1" size="10" style={{fontWeight: 'Bold', fontSize: '20px', textAlign: 'center'}}>
    //         STARTS IN <br />{days} DAYS {hours} HOURS <br />{minutes} MINUTES {seconds} SECONDS 
    //     </IonCol>        
    // </IonRow>
    

    return (<>
         <IonRow style={{marginTop: '30px'}}>
             <StyledTitleIonCol size="2" style={{marginLeft: '15px'}}>Days</StyledTitleIonCol>
             <StyledTitleIonCol size="2" offset="1">Hours</StyledTitleIonCol>
             <StyledTitleIonCol size="2" offset="1">Min</StyledTitleIonCol>
             <StyledTitleIonCol size="2" offset="1">Sec</StyledTitleIonCol>
        </IonRow>
        <IonRow style={{marginTop: '-30px'}}>
            <StyledIonCol size="2" style={{marginLeft: '15px'}}>{days}</StyledIonCol>
            <StyledIonCol size="2" offset="1">{hours}</StyledIonCol>
            <StyledIonCol size="2" offset="1">{minutes}</StyledIonCol>
            <StyledIonCol size="2" offset="1">{seconds}</StyledIonCol>
        </IonRow></>);

};



export default ContestCountdown;