import React from 'react';
import {
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonImg,
    IonButton,
    IonContent,
    IonCard,
    IonCardContent,
} from '@ionic/react';


interface TrackDataWindowProps {
    tracks: any
    trackViewDataResults: any
    pullAdminTrackData: Function
    putAdminTrackData: Function
}

const TrackDataWindow: React.FC<TrackDataWindowProps> = props => {

    function pullData() {
        const idArray = props.tracks.map(i => i.youtube_id);
        props.pullAdminTrackData(idArray);
    }
    let { tracks } = props;
    tracks = tracks.map(t => ({...t, play_count: props.trackViewDataResults[t.youtube_id]}));

    function saveData() {
        props.putAdminTrackData(tracks);
    }
    return  <IonCard><IonCardContent>
        <IonItem>
            <IonButton slot="start" size="default" onClick={() => {pullData()}}>Pull All Data</IonButton></IonItem>

        {tracks.map(i => {
            return <>{i.youtube_id}: {i.play_count}<br/></>;
        })}
        <IonItem><IonButton size="default" onClick={saveData}>Save View Data</IonButton></IonItem>
    </IonCardContent></IonCard>
};


export default TrackDataWindow;