export const SEARCH_CONTEST = "app/contests/SEARCH";
export const SET_SEARCH_CONTEST_RESULTS = "app/contests/SET_SEARCH_CONTEST_RESULTS";
export const SEARCH_MUSIC = "app/music/SEARCH";
export const SET_SEARCH_MUSIC_RESULTS = "app/music/SET_SEARCH_MUSIC_RESULTS";

export const searchContest = payload => {
    return {
        "type": SEARCH_CONTEST,
        payload
    };
};


export const setSearchContestResults = payload => {
    return {
        "type": SET_SEARCH_CONTEST_RESULTS,
        payload
    };
}

export const searchMusic = payload => ({
    type: SEARCH_MUSIC,
    payload
});

export const setSearchMusicResults = payload => ({
    type: SET_SEARCH_MUSIC_RESULTS,
    payload
});