import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import {
    IonGrid, 
    IonRow, 
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect, 
    IonSelectOption, 
    IonToolbar,
    IonTitle,
    IonButton
  } from '@ionic/react';

  
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];


let days = [];

for(let i=1; i<32; i++) {
    days.push(i);
}

let years = [];

for(let i=1950; i<2020; i++) {
    years.push(i);
}


const states =
{
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'District Of Columbia': 'DC',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virgin Islands': 'VI',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY'
};


  
const StyledTitle = styled(IonCol)`
  text-align: center;
  font-weight: bold;
  font-family: 'Clarendon-Serial';
  font-size: 1.2em;
  margin-top:10%;
  color: #0f2070;
`;

const StyledFormElement = styled(IonCol)`
`;

const StyledIonLabel = styled(IonLabel)`
  color: #088C87 !important;
  font-weight: bold !important;
`;

const StyledIonInput = styled(IonInput)`
  color: #000 !important;
  border-color: #088C87 !important;

`;

const StyledIonItem = styled(IonItem)`

  margin-top:7px;

    & :focus {
      color: #088C87 !important;
    }
`;




const SignupForm = (props)  => {
    let initFirstName, 
      initLastName,
      initEmail;

    if (props.userData) {
      if(props.userData.name) {
        const splitname = props.userData.name.split(' ');
        initFirstName = splitname[0];
        if (splitname.length > 1) {
          initLastName = splitname[1];
        }
      }
      initEmail = props.userData.email;
    }

    const [firstName, setFirstName] = useState(initFirstName || "");
    const [lastName, setLastName] = useState(initLastName || "");
    const [email, setEmail] = useState(initEmail || "");
    const [bday, setBday] = useState("");
    const [bmonth, setBmonth] = useState("");
    const [byear, setByear] = useState("1980");
    const [state, setState] = useState("");

    useEffect(() => {
  

    })

  return (
    <IonGrid>
      <IonRow>
          <StyledTitle size="8" offset="2">SIGN UP</StyledTitle>
      </IonRow>
      <IonRow>
        <StyledFormElement size="10" offset="1">
            <StyledIonItem>
              <StyledIonLabel position="floating">First Name</StyledIonLabel>
              <StyledIonInput type="text" 
                value={firstName}
                onIonChange={e => setFirstName(e.detail.value!)}></StyledIonInput>
            </StyledIonItem>
        </StyledFormElement>
        <StyledFormElement size="10" offset="1">
            <StyledIonItem>
              <StyledIonLabel position="floating">Last Name</StyledIonLabel>
              <StyledIonInput type="text" 
                value={lastName}
                onIonChange={e => setLastName(e.detail.value!)}></StyledIonInput>
            </StyledIonItem>
        </StyledFormElement>

        <StyledFormElement size="10" offset="1">
            <StyledIonItem>
              <StyledIonLabel position="floating">Email</StyledIonLabel>
              <StyledIonInput type="email"
              value={email}
              onIonChange={e => setEmail(e.detail.value!)}></StyledIonInput>
            </StyledIonItem>
        </StyledFormElement>



        <StyledFormElement size="11" offset="1" style={{marginTop: '15px'}}>
            <StyledIonLabel position="floating" style={{fontSize: '.8em'}}>Birthday</StyledIonLabel>
        </StyledFormElement>

        <StyledFormElement size="4" offset="1" style={{marginTop: '-10px'}}>
            <IonItem>
                <StyledIonLabel position="floating">Month</StyledIonLabel>
                <IonSelect interface="action-sheet" 
                value={bmonth}
                onIonChange={e => setBmonth(e.detail.value!)}>
                {months.map(m => <IonSelectOption key={m} value={m}>{m}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
        </StyledFormElement>
        <StyledFormElement size="3" style={{marginTop: '-10px'}}>
            <IonItem>
                <StyledIonLabel position="floating">Day</StyledIonLabel>
                <IonSelect interface="action-sheet"
                value={bday}
                onIonChange={e => setBday(e.detail.value!)} >
                {days.map(m => <IonSelectOption key={m} value={m}>{m}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
        </StyledFormElement>
        <StyledFormElement size="3" style={{marginTop: '-10px'}}>
            <IonItem>
                <StyledIonLabel position="floating">Year</StyledIonLabel>
                <IonSelect interface="action-sheet"
                value={byear}
                onIonChange={e => setByear(e.detail.value!)} >
                {years.map(m => <IonSelectOption key={m} value={m}>{m}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
        </StyledFormElement>


        <StyledFormElement size="10" offset="1">
            <IonItem>
                <StyledIonLabel position="floating">State</StyledIonLabel>
                <IonSelect interface="action-sheet" 
                value={state}
                onIonChange={e => setState(e.detail.value!)}>
                {Object.keys(states).map(k => <IonSelectOption key={states[k]} value={states[k]}>{k}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
        </StyledFormElement>


      </IonRow>
        <IonRow style={{marginTop: '30px'}}>
            <IonCol size="10" offset="1">
                <IonToolbar color="primary" style={{textAlign: 'center'}}>
                    <IonButton style={{width: '100%', height: '50px'}}
                        onClick={() => {
                                props.submitSignup({
                                    firstName,
                                    lastName,
                                    email,
                                    phoneNumber: '5555',
                                    birthDate: `${bmonth} ${bday}, ${byear}`,
                                    state,
                                    zip: '55555',
                                });
                        }}
                    >Sign Up</IonButton>
                </IonToolbar>
            </IonCol>
        </IonRow>

    </IonGrid>
  );
};

export default SignupForm;
