import React, { useEffect } from 'react';
import PickCollection from './PickCollection';


interface UserPicksProps {
    contest: any
    contestId: string
    userId: string
    getPickData: Function
    pickData: any
    getTrackData: Function
    trackData: any
    clearTrackData: Function
}

const UserPicks: React.FC<UserPicksProps> = props => {
    useEffect(() => {
        const { userId, contestId, getPickData } = props;
        getPickData({userId, contestId});
    }, []);

    return <PickCollection 
        contest={props.contest}
        trackData={props.trackData}
        picks={props.pickData}
        getTrackData={props.getTrackData}
        clearTrackData={props.clearTrackData}
        userId={props.userId}
    />;
}



export default UserPicks;