// store.js
import { createStore, applyMiddleware, compose,combineReducers  } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import {
  reducers as ducksReducers,
  createSaga,
  createPreloadedState,
} from './ducks/ducks-loader';

// Reducers
import UserReducer from './reducers/User';

// const monitor = window["__SAGA_MONITOR_EXTENSION__"]
const sagaMiddleware = createSagaMiddleware();
const secondRootSaga = createSaga();
const history = createBrowserHistory();

const createRootReducer = history => combineReducers({
  ...ducksReducers,
  router: connectRouter(history),
  title: (state = '') => state,
  user: UserReducer,
  
});

const configureStore = preloadedState => {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(sagaMiddleware),
      applyMiddleware(routerMiddleware(history)),
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    ),
  );
}



const store = configureStore({
  title: 'Jazzdaq',
  user: {}
});
sagaMiddleware.run(rootSaga);

sagaMiddleware.run(secondRootSaga); 


export default store;

