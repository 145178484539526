import React from 'react';
import Styled from 'styled-components';
import { IonButton, IonItem } from '@ionic/react';
import Balance from './Balance';
import Ledger from './Ledger';

const StyledButton = Styled(IonButton)`
    width: 100%;
`;

const mockData = [
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
    ['8/18/20', 'Enter Contest', '-$10', '$100'],
    ['8/18/20', 'Enter Contest', '-$10', '$90'],
    ['8/19/20', 'Enter Contest', '-$10', '$80'],
    ['8/19/20', 'Deposit', '+$50', '$130'],
    ['8/19/20', 'Enter Contest', '-$80', '$50'],
];

interface LedgerPageProps {
    ledger: Array<Array<string>>
    balance: string
    userData: any
}

const LedgerPage: React.FC = props => {

    return <>
        <Balance balance="50" />
        <IonItem>
            <StyledButton size="default">Deposit</StyledButton>
        </IonItem>
        <IonItem style={{textAlign: 'center'}} >
            <a href='#' style={{width: '100%'}}>Withdrawal</a>
        </IonItem>
        <IonItem>
        <Ledger ledger={mockData}/>
        </IonItem>
    </>;
}


export default LedgerPage;


