import React from 'react';
import styled from 'styled-components';


const LogoImg = styled.img`
    float:left;
    height: 45px;
    position: relative;
    margin-top: 20px;

`;



const Logo: React.FC = () => (
    <LogoImg src='assets/Jazzdaq_logo_fullcolor.svg'  onClick={() => {window.location.href='/';}}/>
);



export default Logo;