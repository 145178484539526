//@ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent } from '@ionic/react';

import Header from './../Header';
import BottomNavTabs from './../BottomNavTabs';
import CreateContestForm from './CreateContestForm';
import ResultsComponent from './ResultsComponent';



const CreateContestPage = props  => {


  return (
    <IonContent>
        <Header menu={true} title="Create Contest"/>
        {props.createContestResults ? 
          <ResultsComponent createContestResults={props.createContestResults} gotoContest={props.gotoContest} /> : 
          <CreateContestForm createContest={props.createContest}/>
        }
        <BottomNavTabs selected={3}/>
    </IonContent>
  );
};

export default CreateContestPage;
