import React, { useState, useEffect } from 'react';
import { IonAvatar, IonLabel, IonItem, IonButton } from '@ionic/react';

import ListView from './../Shared/ListView';
import TrackModal from './../Contest/TrackModal';

const Watchlist: React.FC<any> = props => {
    const { trackData, getTrackData, clearTrackData, watchlist, remove } = props;
    
    const [trackModalOpen, setTrackModalOpen] = useState(false)

    let content;

    useEffect(() => {
        if(trackData) {
            setTrackModalOpen(true);
        }
    })

        content = {
            headers: [
                { content: `Tracks`, size: 9 },
                { content: '', size: 2 },
            ],
            rows: []
        };

        content.rows = watchlist.map(p => {
            return {
                cols: [
                <IonItem onClick={() => {
                    getTrackData(p.track_id);
                }}><IonAvatar slot="start">
                    <img src={p.artwork_url60} />
                </IonAvatar>
                <IonLabel>
                    <h3>{p.artist_name} - {p.track_name}</h3>
                </IonLabel></IonItem>,
                 <IonButton 
                    style={{marginTop: '15px'}}
                    onClick={() => {
                        remove(p.track_id);
                    }}
                >Remove</IonButton>
                ]
            }
        });
    
    return <>
        <ListView 
            // @ts-ignore
            content={content}
        /><TrackModal 
            open={trackModalOpen}
            trackData={trackData}
            close={() => {
                setTrackModalOpen(false);
                clearTrackData();
            }}/>
    </>;
};


export default Watchlist;

