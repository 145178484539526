import { createSelector } from 'reselect';
import { get } from 'lodash';


export const getUserState = ({ userData = {} }) => userData;

export const getUserStateSelector = createSelector(
    [getUserState],
    results => results
);



export const getNewUserState = ({ user }) => get(user, 'authResult.user');


export const getNewUserStateSelector = createSelector(
    [getNewUserState],
    results => results
);