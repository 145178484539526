import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent } from '@ionic/react';

import Header from './../Header';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const StyledIonContent = styled(IonContent)`

`;


const StyledImg = styled.img`
  width: 100%;
  position: fixed;
  @media (max-width: 600px) {
    bottom: -180px;
  }
`;


const LoginPage = props  => {
    const [user, setUser] = useState('user');

    useEffect(() => {
    }, []);
  return (
    <StyledIonContent>
        <Header menu={false}/>
        {props.signup ?
          <SignupForm submitSignup={props.submitSignup} userData={props.userData} /> :
          <LoginForm LoginComplete={props.LoginComplete}/>
        }

        {!props.signup ? <StyledImg src='/assets/speakerwithimage.svg' /> : <></>}
    </StyledIonContent>
  );
};

export default LoginPage;
