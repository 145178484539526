import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
    IonItem,
    IonList
} from '@ionic/react';

import SearchResultItem from './SearchResultItem';


const SearchResults = props  => {

  useEffect(() => {
  })

  return (
  <IonList  style={{width: '100%'}}>
      {props.results.map(r => <SearchResultItem 
        key={r.trackId} 
        item={r} 
        add={props.add} 
        showAdd={props.showAdd}
        setSearchVal={props.setSearchVal}
        addLabel={props.addLabel}
      />)}
  </IonList>)
};

export default SearchResults;
