import { all } from 'redux-saga/effects'

import { watchForLogin, watchForSignup } from './loginSaga';
import { watchForGetUserData } from './userSaga';

export default function* rootSaga () {
    yield all([
        watchForLogin(),
        watchForGetUserData(),
        watchForSignup(),
    ]); 
}