
import { put, takeLatest, call, all, select } from 'redux-saga/effects'
import { 
    GET_ADMIN_TRACK_FOR_DATA, 
    PULL_ADMIN_TRACK_DATA,
    PUT_ADMIN_TRACK_DATA,
    GET_ADMIN_TRACK_SOURCE,
    GET_ADMIN_TRACK_YOUTUBE_SOURCES,
    SET_ADMIN_YOUTUBE_SOURCE,
    setAdminTrackData,
    setPulledAdminTrackData, 
    getAdminTrackForData,
    setAdminTrackSourceData,
    setAdminTrackYoutubeSources,
} from './actions';
import { 
    adminGetTracksForData,
    adminPullTrackData,
    adminSaveTrackData,
    adminGetTrackSource,
    adminGetYoutubeSource,
    adminSetYoutubeSource,
} from './../../actions/api';

function* getAdminTrackData() {
    try{
        const tracks = yield call(adminGetTracksForData);
        yield put(setAdminTrackData(tracks.data));
    }catch(e) {
        console.error(e);
    }
}

function* pullAdminTrackData(data) {
    try{
        const { payload } = data;
        const trackData = yield call(adminPullTrackData, payload);
        yield put(setPulledAdminTrackData(trackData.data));
    }catch(e) {
        console.error(e);
    }
}

function* putAdminTrackData(data) {
    try{
        const { payload } = data;
        yield call(adminSaveTrackData, payload);
        yield put(getAdminTrackForData());
    }catch(e) {
        console.error(e);
    }
}

function* getAdminTrackSources() {
    try{
        const results = yield call(adminGetTrackSource);
        yield put(setAdminTrackSourceData(results.data));
    }catch(e) {
        console.error(e);
    }
}

function* getAdminYoutubeSource(data) {
    try{
        const { payload } = data;
        const results = yield call(adminGetYoutubeSource, payload);
        yield put(setAdminTrackYoutubeSources(results.data));
    }catch(e) {
        console.error(e);
    }
}

function* setAdminYoutubeSource(data) {
    try{
        const { payload } = data;
        yield call(adminSetYoutubeSource, payload);
    }catch(e) {
        console.error(e);
    }
}

export function* watchForAdminTasks() {
    yield takeLatest(SET_ADMIN_YOUTUBE_SOURCE, setAdminYoutubeSource);
    yield takeLatest(GET_ADMIN_TRACK_YOUTUBE_SOURCES, getAdminYoutubeSource);
    yield takeLatest(GET_ADMIN_TRACK_SOURCE, getAdminTrackSources);
    yield takeLatest(PUT_ADMIN_TRACK_DATA, putAdminTrackData);
    yield takeLatest(PULL_ADMIN_TRACK_DATA, pullAdminTrackData);
    yield takeLatest(GET_ADMIN_TRACK_FOR_DATA, getAdminTrackData);
}
