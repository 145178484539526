import React from 'react';
import Styled from 'styled-components';
import {
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react';

const StyledTitleRow = Styled(IonRow)`
    font-weight: bold;
    border-bottom: 1px solid black;
`;
const BorderBottomRow = Styled(IonRow)`
    border-bottom: 1px solid grey;
`;


interface LedgerProps {
    ledger: Array<Array<string>>
}

const Ledger: React.FC<LedgerProps> = props => {
    const { ledger } = props;

    return (
        <IonGrid>
            <StyledTitleRow>
                <IonCol size="6">Description</IonCol>
                <IonCol size="3">Change</IonCol>
                <IonCol size="3">Balance</IonCol>
            </StyledTitleRow>
            {ledger.map(v => {
               return (<>
                <IonRow><IonCol size="12">{v[0]}</IonCol></IonRow>
                <BorderBottomRow>
                    <IonCol size="5" offset="1">{v[1]}</IonCol>
                    <IonCol size="3">{v[2]}</IonCol>
                    <IonCol size="3">{v[3]}</IonCol>
                </BorderBottomRow>
               </>) 
            })}
        </IonGrid>
    );
};


export default Ledger;