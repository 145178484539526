export const SET_FEATURED_DATA = "app/featured/SET_FEATURED_DATA";
export const GET_FEATURED_DATA = "app/featured/GET_FEATURED_DATA";
export const SAVE_FEATURED_DATA = "app/featured/admin/SAVE_FEATURED_DATA";


export const setFeaturedData = data => {
    return {
        "type": SET_FEATURED_DATA,
        payload: data
    }
}

export const getFeaturedData = () => {
    return {
        type: GET_FEATURED_DATA
    }
}

export const saveFeaturedData = payload => ({
    type: SAVE_FEATURED_DATA,
    payload
});