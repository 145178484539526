import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
    IonItem,
    IonAvatar,
    IonLabel,
} from '@ionic/react';

const StyledIonItem = styled(IonItem)`
    display: block;
`;



const SearchResultItem = ({item})  => {

  useEffect(() => {
  })

  return (
    <StyledIonItem href={`/contest/${item.id}`}>
        <IonAvatar slot="start">
            <img src='https://jazzdaq-public.s3.us-east-2.amazonaws.com/my_contest_icon.svg' />
        </IonAvatar>
        <IonLabel>
            <h1>{item.name}</h1>
            <h3>Owner: {item.first_name} {item.last_name}</h3>
            <h3>Players: {item.player_count}</h3>
        </IonLabel>
    </StyledIonItem>
  )
};

export default SearchResultItem;
