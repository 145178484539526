import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
    IonItem,
    IonList
} from '@ionic/react';

import SearchResultItem from './SearchResultItem';


const SearchResults = props  => {

  useEffect(() => {
  })

  return (
  <IonList>
      {props.results.map(r => <SearchResultItem key={r.id} item={r} />)}
  </IonList>)
};

export default SearchResults;
