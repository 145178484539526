import React, { useState, useEffect } from 'react';


import SearchComponent from './../Shared/SearchComponent';

interface FeatureFormProps {
    featured: any
    saveFeaturedData: Function
    musicResults: any
    searchMusic: Function
}


const FeatureForm: React.FC<FeatureFormProps> = props => {

    const save = song => {
        const saveObj = {
            artist: song.artistName,
            title: song.trackName,
            artwork: song.artworkUrl60,
            playUrl: song.previewUrl,
            id: props.featured.id
        };
        props.saveFeaturedData(saveObj);
    }

    return  <SearchComponent 
                    search={props.searchMusic}
                    results={props.musicResults}
                    add={e=>{ save(e)}}
                    showAdd={true}
                    addLabel="Save"
                />
};



export default FeatureForm;