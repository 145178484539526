import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { get } from 'lodash';
import AdminPage from './../components/Admin/AdminPage';
import { 
    getAdminTrackForData,
    pullAdminTrackData,
    putAdminTrackData,
    getAdminTrackSource,
    getAdminTrackYoutubeSources,
    setAdminYoutubeSource,
 } from './../ducks/admin/actions';
import { 
    getAdminTrackDataResults,
    getAdminTrackViewDataResults,
    getAdminTrackSourceResults,
    getAdminTrackYoutubeSourcesResults,
} from './../ducks/admin/selectors';

import { getFeaturedData, saveFeaturedData } from './../ducks/features/actions';
import { getFeaturedResults } from './../ducks/features/selectors';
import {
    searchMusic
} from './../ducks/search/actions';

import {
   getMusicSearchResults as getMusicSearchResultsSelector
} from './../ducks/search/selectors';


interface AdminContainerProps {
    track: string
    setTrack: Function
    p: string
    getAdminTrackForData: Function
    pullAdminTrackData: Function
    trackResults: any
    trackViewDataResults: any
    putAdminTrackData: Function
    getAdminTrackSource: Function
    getAdminYoutubeSource: Function
    setAdminYoutubeSource: Function
    getFeaturedData: Function
    saveFeaturedData: Function
    trackYoutubeData: any
    trackSourceData: any
    featured: any
    searchMusic: Function
    musicResults: any
}


const AdminContainer: React.FC<AdminContainerProps> = (state, props) => {
    const { 
        track,
        setTrack,
        p,
        getAdminTrackForData,
        pullAdminTrackData,
        trackResults,
        trackViewDataResults,
        putAdminTrackData,
        getAdminTrackSource,
        getAdminYoutubeSource,
        trackYoutubeData,
        trackSourceData,
        setAdminYoutubeSource,
        getFeaturedData,
        featured,
        saveFeaturedData,
        searchMusic,
        musicResults,
} = state;
    useEffect(() => {
        console.log(state.p);
        if (p === 'track') getAdminTrackForData();
        if (p === 'source' && !track) getAdminTrackSource();
        if (p === 'source' && track) getAdminYoutubeSource(track);
        if (p === 'feature') getFeaturedData();
    }, [state.p]);

  return <AdminPage 
    track={track} 
    setTrack={setTrack} 
    page={p}
    trackResults={trackResults}
    trackViewDataResults={trackViewDataResults}
    pullAdminTrackData={pullAdminTrackData}
    putAdminTrackData={putAdminTrackData}
    trackSourceData={trackSourceData}
    trackYoutubeData={trackYoutubeData}
    setAdminYoutubeSource={setAdminYoutubeSource}
    featured={featured}
    saveFeaturedData={saveFeaturedData}
    searchMusic={searchMusic}
    musicResults={musicResults}
    />
};


const mapPropsToDispatch = (dispatch, props) => ({
    setTrack: trackId => {
        props.history.push(`?track=${trackId}`);
    },
    getAdminTrackForData: () => dispatch(getAdminTrackForData()),
    getAdminTrackSource: () => dispatch(getAdminTrackSource()),
    pullAdminTrackData: ids => dispatch(pullAdminTrackData(ids)),
    putAdminTrackData: trackData => dispatch(putAdminTrackData(trackData)),
    getAdminYoutubeSource: id => dispatch(getAdminTrackYoutubeSources(id)),
    setAdminYoutubeSource: data => dispatch(setAdminYoutubeSource(data)),
    getFeaturedData: () => dispatch(getFeaturedData()),
    saveFeaturedData: data => dispatch(saveFeaturedData(data)),
    searchMusic: data => dispatch(searchMusic(data)),
    ...props
});


const mapPropsToState = (state, props) => {
    return {
        track: get(queryString.parse(get(props, 'location.search')), 'track') || null,
        p: get(queryString.parse(get(props, 'location.search')), 'p') || null,
        trackResults: getAdminTrackDataResults(state),
        trackViewDataResults: getAdminTrackViewDataResults(state),
        trackSourceData: getAdminTrackSourceResults(state),
        trackYoutubeData: getAdminTrackYoutubeSourcesResults(state),
        featured: getFeaturedResults(state),
        musicResults: getMusicSearchResultsSelector(state),
    };
};


export default connect(
  mapPropsToState, 
  mapPropsToDispatch
)(AdminContainer);
