import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonItem,
    IonInput,
    IonButton,
  } from '@ionic/react';



const StyledIonText = styled.h3`
    font-weight: bold;
    text-align:center;
    font-family: 'Clarendon-Serial';
    color: #0f2070;
    margin-top: 10%;
`;


const StyledSubText = styled.p`
    font-weight: bold;
    font-family: 'Clarendon-Serial';
    color: #088C87;
    font-size: 1.3em;
    margin-bottom: 10px;
`;

const StyledCode = styled(IonInput)`
    font-weight: bold;
    text-align:center;
    font-family: 'Clarendon-Serial';
    color: #0f2070;
    margin-top: 10px;
`;

const StyledIonButton = styled(IonButton)`
  width: 100%;
  height: 50px;
  font-family: 'Clarendon-Serial';
  font-weight: bold;
  margin-top: 10%;
`;

const StyledImg = styled.img`

`;

const ResultsComponent = props  => {

    const gotoContestHandler = () => {
        props.gotoContest(props.createContestResults.id);
    };

    return (
        <>
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>
                    <StyledIonText>THANK YOU FOR CREATING A CONTEST!!!</StyledIonText>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{textAlign: "center"}}>
                <StyledSubText>You're helping to make a difference <br />
                for emerging artists.<br /><br />Share this code so your friends can join!<br /><br /></StyledSubText>
                <IonItem style={{textAlign: "center"}}>
                <StyledCode readOnly value={props.createContestResults.code} />
                </IonItem>
                <IonItem>
                    <StyledIonButton onClick={gotoContestHandler} >Go To Your Contest!</StyledIonButton>
                </IonItem>
            </IonCardContent>
        </IonCard>
        <StyledImg src='/assets/walkthrough1.png'/>
        </>
    )
};

export default ResultsComponent;
