
import { put, takeLatest, call, } from 'redux-saga/effects'
import { 
    getContests, 
    getContestHistory,
    createContest,
    getContest,
    joinContest,
    addPickToCollection as addPickAPICall,
    removePickFromCollection as removePickAPICall,
    getTrackData as getTrackDataCall,
    getUserPicks,
    getContestTracks as getContestTrackCall,
    
} from './../../actions/api';
import { 
    setContestData, 
    setContest,
    setCreateContestResponse,
    joinContestSuccess,
    getContest as getContestAction,
    setTrackData as setTrackDataAction,
    setContestTracks as setContestTracksAction,
    setPickData,
    GET_CONTEST_DATA, 
    CREATE_CONTEST, 
    GET_CONTEST,
    JOIN_CONTEST,
    ADD_PICK_TO_CONTEST,
    REMOVE_PICK_FROM_COLLECTION,
    GET_CONTEST_HISTORY,
    GET_TRACK_DATA,
    GET_PICK_DATA,
    getPickData,
    GET_CONTEST_TRACKS,
    REMOVE_FROM_CONTEST,
    removeFromContest

} from './actions';

import { getAddPick as getAddPickSelector } from './selectors';
import { caretDownCircle } from 'ionicons/icons';

import {
    setErrorMessage,
    showError,
} from './../generic/actions';
import { pathToFileURL } from 'url';

 function* handleGetContests(data) {
    try{
        const userInfo = yield call(getContests, '');
        yield put(setContestData(userInfo.data));
    }catch(e) {
        if (e.response && (e.response.status > 400 && e.response.status < 500 )) {
            if (!data.data.signup) {
                data.data.history.push('/login');
            }else{
                data.data.history.push('/signup');
            }
        }
    }
}


function* handleCreateContest(data) {
    const { formData, history } = data.payload;
    try{
        const contestData = yield call(createContest, formData);
        yield put(setCreateContestResponse(contestData.data));
        // history.push('/');
    }catch(e) {
        if (e.response && (e.response.status > 400 && e.response.status < 500 )) {
            history.push('/login');
        }
    }
}



function* handleGetContest(data) {
    const { formData, history } = data.payload;
    try{
        const contestData = yield call(getContest, formData)
        yield put(setContest(contestData.data));
    }catch(e) {

        if (e.response && e.response.status === 404) {
            yield put(setContest({error: 'No Contest Found'}));
        }

        if (e.response && (e.response.status === 400 && e.response.status < 500 )) {
            history.push('/login');
        }
    }
};


function* handleJoinContest(data) {
    const {id} = data;
    const email = data.email || '';
    try{
        yield call(joinContest, id, email);
        yield put(getContestAction({formData: id, history: {}}));
    }catch(e) {
        yield put(joinContestSuccess(e.response));
    }
}

function* handleAddPickToCollection(data) {
    const pickData = data.payload;
    const cleanPickData = getAddPickSelector(pickData);
    try{
        const results = yield call(addPickAPICall, cleanPickData);
        yield put(getContestAction({formData: pickData.contestId}));
    }catch(e) {
        yield put(setErrorMessage(e.response.data));
        yield put(showError(true));
        setTimeout(() => {
            put(showError(false));
        }, 2000);
    }
}

function* handleRemovePickToCollection(data) {
    const pickData = data.payload;
    try{
        const results = yield call(removePickAPICall, {contestId: pickData.contestId, trackId: pickData.trackId});
        yield put(getContestAction({formData: pickData.contestId}));
    }catch(e) {

    }
}

function* handleGetContestHistory() {
    try{
        const results = yield call(getContestHistory, '');
        yield put(setContestData(results.data));
    }catch(e) {
        yield put(setContestData([]));
    }
}

function* handleGetTrackData(data) {
    try {
        const { payload } = data;
        const results = yield call(getTrackDataCall, payload);
        yield put(setTrackDataAction(results.data));
    } catch (e) {
        console.error(e);
    }
}


function* handleGetPickData(data) {
    try {
        const { payload } = data;
        const results = yield call(getUserPicks, payload);
        yield put(setPickData(results.data));
    }catch(e) {
        console.error(e);
    }
}

function* handleGetContestTracks(data) {
    try{
        const { payload } = data;
        const results = yield call(getContestTrackCall, payload);
        yield put(setContestTracksAction(results.data));
    }catch(e) {
        console.error(e);
    }
}

function* handleRemoveFromContest(data) {
    try{
        const { contestId, userId } = data;
        yield call(removeFromContest, contestId, userId);
    }catch(e) {
        console.error(e);
    }
}

export function* watchForGetContestTracks() {
    yield takeLatest(GET_CONTEST_TRACKS, handleGetContestTracks);
}

export function* watchForGetPickData() {
    yield takeLatest(GET_PICK_DATA, handleGetPickData);
}

export function* watchForGetTrackData() {
    yield takeLatest(GET_TRACK_DATA, handleGetTrackData);
}

export function* watchForGetContestHistory() {
    yield takeLatest(GET_CONTEST_HISTORY, handleGetContestHistory);
}

export function* watchForGetUserData() {
    yield takeLatest(GET_CONTEST_DATA, handleGetContests);
}

export function* watchForCreateContest() {
    yield takeLatest(CREATE_CONTEST, handleCreateContest);
}

export function* watchForGetContest() {
    yield takeLatest(GET_CONTEST, handleGetContest);
}

export function* watchForJoinContest() {
    yield takeLatest(JOIN_CONTEST, handleJoinContest);
    yield takeLatest(REMOVE_FROM_CONTEST, handleRemoveFromContest);
}

export function* watchForAddPickToCollection() {
    yield takeLatest(ADD_PICK_TO_CONTEST, handleAddPickToCollection);
}

export function* watchForRemovePickToCollection() {
    yield takeLatest(REMOVE_PICK_FROM_COLLECTION, handleRemovePickToCollection);
}