import React from 'react';
import Styled from 'styled-components';

const StyledDiv = Styled.div`
    width: 100%;
    border-bottom: 1px solid #0f2070;
    height: 60px;
    text-align: center;
`;

const StyledBalanceTitle = Styled.span`
    color: green;
    font-weight: #0f2070;
`;

const StyledBalance = Styled.span`
    color: #0f2070;
    font-size: 2em;
    
`;

interface BalanceProps {
    balance: String
}

const Balance: React.FC<BalanceProps> = props => {
    const { balance } = props;

    return (<StyledDiv>
        <StyledBalanceTitle>Balance</StyledBalanceTitle> <br />
        <StyledBalance>${balance}</StyledBalance>
    </StyledDiv>)
};

export default Balance;