// @ts-nocheck
import {
    SET_ADMIN_TRACK_DATA,
    SET_PULLED_ADMIN_TRACK_DATA,
    SET_ADMIN_TRACK_SOURCE_DATA,
    SET_ADMIN_TRACK_YOUTUBE_SOURCES
  } from './actions';
  

export function adminTrackData(state = [], { type, payload = [] } = {}) {
    switch (type) {
        case SET_ADMIN_TRACK_DATA:
                return payload;
        default: 
            return state;
    }
};
  

export function adminTrackViewData(state = {}, { type, payload = {}} = {}) {
    switch (type) {
        case SET_PULLED_ADMIN_TRACK_DATA:
            return payload;
        default: 
            return state;
    }

}


export function adminTrackSources(state=[], { type, payload = []} = {}) {
    switch (type) {
        case SET_ADMIN_TRACK_SOURCE_DATA:
            return payload;
        default: 
            return state;
    }
};

export function adminTrackYoutubeSources(state=[], { type, payload = []} = {}) {
    switch (type) {
        case SET_ADMIN_TRACK_YOUTUBE_SOURCES:
            return payload;
        default:
            return state;
    }
};