import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { 
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonItem,
 } from '@ionic/react';
import { ContestType } from './../../actions/apiTypes';


interface ContestSegmentTabsProps {
    contest: ContestType["contestData"]
    changePage: Function
    page: string
}


const ContestSegmentTabs: React.FC<ContestSegmentTabsProps> = props  => {
    useEffect(() => {

    }, []);
  return (     
    <IonItem>  
        <IonSegment value={props.page}  onIonChange={e => {
                props.changePage(e.detail.value);
            }}>
            <IonSegmentButton value="0" hidden={Boolean(props.contest.started)}>
                <IonLabel>Contest</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="1">
                <IonLabel>{props.contest.joined || props.contest.started ? 'Leaderboard' : `Guest List`}</IonLabel>
            </IonSegmentButton>
            {props.contest.joined ?
            (<IonSegmentButton value="3" >
                <IonLabel>My Collection</IonLabel>
            </IonSegmentButton>):
            (!props.contest.started ? <IonSegmentButton value="2" >
                <IonLabel>Join</IonLabel>
            </IonSegmentButton>:<></>)}

            {props.contest.started ?<IonSegmentButton value="6" >
                <IonLabel>Playlist</IonLabel>
            </IonSegmentButton> : <></>}

        </IonSegment>
    </IonItem> 
    
  );
};

export default ContestSegmentTabs;
