import React from 'react';
import styled from 'styled-components'; 
import {
    IonRow,
    IonCol,
    IonItem,
} from '@ionic/react';

const StyledIonRow = styled(IonRow)`
    border-bottom: 1px solid #088C87;
    color: #0f2070;
    font-family: 'Domaine-Regular';
`;

interface ListProps {
    headers: Array<{
        content: string,
        size: string
    }>,
    content: {
        cols: Array<React.ReactNode>,
        onClick?: any
    }
}

const ListElement: React.FC<ListProps> = props => {
    return (<StyledIonRow onClick={props.content.onClick} type="button">
        {props.content.cols.map((col, index) => {
            return <IonCol 
                size={props.headers[index].size}
            >{col}</IonCol>
        })}
    </StyledIonRow>);
}


export default ListElement;