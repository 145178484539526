import { createSelector } from 'reselect';

export const getAdminTrackDataState = ({ adminTrackData = []}) => adminTrackData;

export const getAdminTrackDataResults = createSelector(
    [getAdminTrackDataState],
    results => results
);

export const getAdminTrackViewDataState = ({ adminTrackViewData = {}}) => adminTrackViewData;

export const getAdminTrackViewDataResults = createSelector(
    [getAdminTrackViewDataState],
    results => results
);

export const getAdminTrackSourceState = ({ adminTrackSources = [] }) => adminTrackSources;

export const getAdminTrackSourceResults = createSelector(
    [getAdminTrackSourceState],
    results => results
);

export const getAdminTrackYoutubeSourcesState = ({ adminTrackYoutubeSources = [] }) => adminTrackYoutubeSources;

export const getAdminTrackYoutubeSourcesResults = createSelector(
    [getAdminTrackYoutubeSourcesState],
    results => results
);