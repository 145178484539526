import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import WalletPage from './../components/Wallet/WalletPage';

const WalletContainer: React.FC = (state, props) => {

  useEffect(() => {
  }, [])

  // @ts-ignore
  return (<WalletPage />)
};

const mapPropsToDispatch = dispatch => {
  return {
    // getContests: () => dispatch(getContestHistory())
  }
};


const mapPropsToState = (state, props) => {
  const newProps = {
    contests: state.contests,
  };
  return newProps;
}


export default connect(
  mapPropsToState, 
  mapPropsToDispatch
)(WalletContainer);
