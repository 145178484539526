import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ContestSearchPage from './../components/ContestSearch/ContestSearchPage';
import { searchContest } from './../ducks/search/actions';
import { getSearchResults } from './../ducks/search/selectors';

const ContestSearch: React.FC = (state, props) => {
    return (<ContestSearchPage 
        //@ts-ignore
        searchContest={state.searchContest}
        //@ts-ignore
        searchResults={state.contestSearchResults}
        />)
};

const mapPropsToDispatch = (dispatch, props) => ({
    searchContest: value => dispatch(searchContest({formData: value}))
});

const mapPropsToState = state => ({ contestSearchResults: getSearchResults(state)});



export default connect(
        mapPropsToState, 
        mapPropsToDispatch
    )(ContestSearch);
