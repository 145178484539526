



export const loginComplete = (userData, history) => {
    return {
        type: 'LOGIN_COMPLETE',
        data: {...userData, history}
    };
};

export const signUpSubmit = (userData, history) => {
    return {
        type: 'SIGNUP',
        data: {user: userData, history}
    }
}; 