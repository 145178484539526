import React from 'react';
import { 
    IonRow,
    IonCol,
    IonItem,
    IonButton,
 } from '@ionic/react';
import styled from 'styled-components';



const StyledIonRow = styled(IonRow)`
    border-bottom: 1px solid #088C87;
    z-index: 9999;
`;


const StyledIcon = styled.img`
    width: 40px;
    fill: blue;
`;

const StyledTitleCol = styled(IonCol)`
    color: #0F2070;
    font-weight: Bold;
    padding-top: 12px;
`;
const StyledRankCol = styled(IonCol)`
    color: #FD795C;
    font-weight: Bold;
    padding-top: 12px;
`;

const StyledChevron = styled.span`
    &:before {
        font-size: 40px;
        color: #F8B302;
      border-style: solid;
      border-width: 0.05em 0.05em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;
      top: 0.15em;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 0.45em;
      left: 0;
    transform: rotate(45deg);
  }
`;

const StyledIonButton = styled(IonButton)`
    --box-shadow: none;
`;


interface ElementProps {
    contestName: String
    points:string
    rank: number
    contestId: string
    started: boolean
    complete?: boolean
};

const getStatus = (started, complete = false) => {
    if (started && !complete) {
        return 'Started';
    }else if(started && complete) {
        return 'Finished';
    }else{
        return 'Not Started';
    }
};


const ContestElement: React.FC<ElementProps> = ( props ) => {

    return (
        <StyledIonRow>
            <IonItem  type="button" href={`/contest/${props.contestId}`} style={{width: '100%'}}>
                <IonCol size="2" offset="1">
                    <StyledIcon src="/assets/icon/speaker_icon.svg"/>
                </IonCol>
                <StyledTitleCol size="5">{props.contestName}</StyledTitleCol>
                {/* <StyledTitleCol size="2" offset="1">{props.points}</StyledTitleCol> */}
                {/* <StyledRankCol size="2">{props.rank}</StyledRankCol> */}
                {/* <IonCol size="1"><StyledIonButton size="large" color="none"><StyledChevron/></StyledIonButton></IonCol> */}
                <StyledRankCol size="3">{getStatus(props.started, props.complete)}</StyledRankCol>
            </IonItem>
        </StyledIonRow>);



};



export default ContestElement;