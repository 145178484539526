export const SET_CONTEST_DATA = "app/contests/SET_CONTEST_DATA";
export const GET_CONTEST_DATA = "app/contests/GET_CONTEST_DATA";
export const GET_CONTEST_HISTORY = "app/contests/GET_CONTEST_HISTORY";
export const CREATE_CONTEST = "app/contests/CREATE_CONTEST";
export const CREATE_CONTEST_RESPONSE = "app/contests/CREATE_CONTEST_RESPONSE";
export const GET_CONTEST = "app/contest/GET_CONTEST";
export const SET_SINGLE_CONTEST_DATA = "app/contest/SET_SINGLE_CONTEST_DATA";
export const JOIN_CONTEST = "app/contest/JOIN_CONTEST";
export const REMOVE_FROM_CONTEST = "app/contest/REMOVE_FROM_CONTEST";
export const JOIN_CONTEST_SUCCESS = "app/contest/JOIN_CONTEST_SUCCESS";
export const JOIN_CONTEST_FAILURE = "app/contest/JOIN_CONTEST_FAILURE";
export const ADD_PICK_TO_CONTEST = "app/contest/ADD_PICK_TO_CONTEST";
export const REMOVE_PICK_FROM_COLLECTION = "app/contest/REMOVE_PICK_FROM_COLLECTION";
export const GET_TRACK_DATA = "app/contest/GET_TRACK_DATA";
export const SET_TRACK_DATA = "app/contest/SET_TRACK_DATA";
export const GET_PICK_DATA = "app/contest/GET_PICK_DATA";
export const SET_PICK_DATA = "app/contest/SET_PICK_DATA";
export const GET_CONTEST_TRACKS = "app/contest/GET_CONTEST_TRACKS";
export const SET_CONTEST_TRACKS = "app/contest/SET_CONTEST_TRACKS";


export const setContestData = payload => {
    return {
        "type": SET_CONTEST_DATA,
        payload
    };
};

export const getContestData = () => {
    return {
        type: GET_CONTEST_DATA,
        payload: {}
    };
};

export const getContestHistory = () => ({
    type: GET_CONTEST_HISTORY,
    payload: {}
});

export const createContest = payload => {
    return {
        "type": CREATE_CONTEST,
        payload
    };
};

export const getContest = payload => ({
    "type": GET_CONTEST,
    payload
});

export const setContest = payload => {
    return {
        "type": SET_SINGLE_CONTEST_DATA,
        payload
    };
};


export const setCreateContestResponse = payload => ({
    "type": CREATE_CONTEST_RESPONSE,
    payload
})

export const joinContest = (id, email) => ({
    type: JOIN_CONTEST,
    id, 
    email
});

export const removeFromContest = (contestId, userId) => ({
    type: REMOVE_FROM_CONTEST,
    contestId, 
    userId
});

export const joinContestSuccess = payload => ({
    type: JOIN_CONTEST_SUCCESS,
    payload
});

export const addPickToCollection = payload => ({
    type: ADD_PICK_TO_CONTEST,
    payload
});

export const removePickFromCollection = payload => ({
    type: REMOVE_PICK_FROM_COLLECTION,
    payload
});

export const getTrackData = payload => ({
    type: GET_TRACK_DATA,
    payload
});

export const setTrackData  = payload => ({
    type: SET_TRACK_DATA,
    payload
});

export const getPickData = payload => ({
    type: GET_PICK_DATA,
    payload
});

export const setPickData = payload => ({
    type: SET_PICK_DATA,
    payload
});


export const getContestTracks = payload => ({
    type: GET_CONTEST_TRACKS,
    payload
});

export const setContestTracks = payload => ({
    type: SET_CONTEST_TRACKS,
    payload
});