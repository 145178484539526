import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { 
    IonItem,
    IonAvatar,
    IonLabel,
    IonButton
} from '@ionic/react';

const StyledIonItem = styled(IonItem)`
    display: block;
    z-index: 99999;
`;

const StyledReleaseDate = styled.span`
    font-size: .8em;
    color: #1d1d1d;
`;


const SearchResultItem = props  => {
    const { contestId } = useParams();
    const {item, add, setSearchVal} = props;
  useEffect(() => {
  })
  const releaseDate = new Date(item.releaseDate);
  const now = new Date();
  const Difference_In_Days = Math.floor((now.getTime() - releaseDate.getTime()) / (1000 * 3600 * 24)); 
  return (
    <StyledIonItem>
        <IonAvatar slot="start">
            <img src={item.artworkUrl30} />
        </IonAvatar>
        <IonLabel>
            <h3>{item.artistName} - {item.trackName}</h3>
            <StyledReleaseDate>Released {Difference_In_Days} ago: {releaseDate.toLocaleDateString()}</StyledReleaseDate>
        </IonLabel>

        {props.showAdd ? <IonButton ion-item onClick={() => {
            setSearchVal("");
            add({...item, contestId});
        }}>{props.addLabel || "Add"}</IonButton> : <></>}
    </StyledIonItem>
  )
};

export default SearchResultItem;
