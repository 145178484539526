import React, { useEffect, useState } from 'react';

import {} from '@ionic/react';
import {} from 'ionicons/icons';

import SearchComponent from './../Shared/SearchComponent';
import PickCollection from './PickCollection';

interface CollectionScreenProps {
    searchMusic: Function
    addPickToCollection: Function
    removePickFromCollection: Function
    musicSearchResults: any
    contest: any
    trackData: any
    getTrackData: Function
    clearTrackData: Function
    userData: any
}

const CollectionScreen: React.FC<CollectionScreenProps> = props => {
    useEffect(() => {
        return () => {
            props.searchMusic("");
        }
    }, props.contest)
    return <>
        <SearchComponent 
            type="songs"
            search={props.searchMusic}
            results={props.musicSearchResults}
            add={props.addPickToCollection}
            showAdd={props.contest.pickCount > props.contest.picks.length}
        />
        {!props.musicSearchResults.length ? 
        <PickCollection
            contest={props.contest}
            remove={props.removePickFromCollection}
            trackData={props.trackData}
            getTrackData={props.getTrackData}
            clearTrackData={props.clearTrackData}
            userData={props.userData}
        /> : <></>}
     
    </>
};



export default CollectionScreen;