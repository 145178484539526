import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { 
  IonContent,
} from '@ionic/react';
import { debounce } from 'lodash';

import Header from '../Header';
import BottomNavTabs from '../BottomNavTabs';
import ContestSearchWindow from './ContestSearchWindow';


const StyledIonContent = styled(IonContent)`

`;



const ContestSearchPage = props  => {

  const [searchVal, setSearchVal] = useState("");

  const SearchContest = value => {
      if (value) {
        props.searchContest(value);
        setSearchVal("");
      }else{
        props.searchResults.results = 0;
        props.searchResults.hits = [];
        setSearchVal("reload");
      }
  };

  useEffect(() => {
  })
  return (
    <StyledIonContent>
        <Header menu={true} title="Find Contests"/>
        <ContestSearchWindow 
          setSearchVal={SearchContest} 
          searchResults={props.searchResults}/>
        {props.searchResults.results ? <></> : <BottomNavTabs selected={4}/>}
    </StyledIonContent>
  );
};

export default ContestSearchPage;
