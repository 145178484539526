import { spawn, all } from 'redux-saga/effects';

import { sagas as sagaRegistry } from './loader';
//@ts-ignore;
export const prepSagas = (sagas = {}, options = []) => Object.values(sagas).map(saga => spawn(saga, ...options));

export function createSaga(sagas = sagaRegistry) {
  return function* rootSaga(...options) {
    yield all(prepSagas(sagas, options));
  };
}
