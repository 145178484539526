import {
    SET_FEATURED_DATA
  } from './actions';
  
  const INITIAL_STATE = {
      name: "",
      artist: "",
      art: ""
  };
  
// @ts-ignore
export function featured(state = INITIAL_STATE, { type, payload = {} } = {}) {
    switch (type) {
        case SET_FEATURED_DATA:
            return payload;

        default: 
            return state;

    }
}
  