import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';

import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
declare global {
    const GLOBAL_CONFIG: GlobalConfigObject
}

interface GlobalConfigObject {
    FIREBASE_API_KEY: String,
    FIREBASE_AUTH_DOMAIN: String
    FIREBASE_APP_ID: String
    FIREBASE_PROJECT_ID: String
    FIREBASE_STORAGE_BUCKET: String
    FIREBASE_MEASUREMENT_ID: String
    FIREBASE_MESSAGE_SENDER_ID: String
    BASE_URL: String
}

Sentry.init({dsn: "https://0015597e29114cb9ab791b3ec371e79b@o419738.ingest.sentry.io/5336470"});
/**
 * REACT_APP_FIREBASE_APP_ID="1:740214146851:web:0c6575aa858730fece6e96"
REACT_APP_FIREBASE_API_KEY="AIzaSyAhTotnpZ6FBdrWE4y6yu6JwNKcXI4Tfl8"
REACT_APP_FIREBASE_DB_URL="https://jazzdaq-1ccc5.firebaseio.com"
REACT_APP_FIREBASE_PROJECT_ID="jazzdaq-1ccc5"
REACT_APP_FIREBASE_STORAGE_BUCKET="jazzdaq-1ccc5.appspot.com"
REACT_APP_FIREBASE_AUTH_DOMAIN="jazzdaq-1ccc5.firebaseapp.com"
REACT_APP_FIREBASE_MEASUREMENT_ID="G-XQCEKG0FN1"
REACT_APP_FIREBASE_MESSAGE_SENDER_ID="740214146851"
REACT_APP_BASE_URL="https://app-be.jazzdaq.com"
 */
const firebaseConfig = {
    apiKey: GLOBAL_CONFIG.FIREBASE_API_KEY,
    authDomain: GLOBAL_CONFIG.FIREBASE_AUTH_DOMAIN,
    projectId: GLOBAL_CONFIG.FIREBASE_PROJECT_ID,
    storageBucket: GLOBAL_CONFIG.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: GLOBAL_CONFIG.FIREBASE_MESSAGE_SENDER_ID,
    appId: GLOBAL_CONFIG.FIREBASE_APP_ID,
    measurementId: GLOBAL_CONFIG.FIREBASE_MEASUREMENT_ID
  };

  firebase.initializeApp(firebaseConfig);
firebase.analytics();




ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
