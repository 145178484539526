//@ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent } from '@ionic/react';

import Header from './Header';
import BottomNavTabs from './BottomNavTabs';
import Featured from './Featured';
import ContestList from './ContestList';

const StyledIonContent = styled(IonContent)`

`;




const HistoryPage = props  => {
    useEffect(() => {

    }, []);


  return (
    <StyledIonContent>
        <Header menu={true} title="History"/>
        <ContestList contestListTitle="Your Contest History" contests={props.contests} history={true}/>
        <BottomNavTabs selected={2}/>
    </StyledIonContent>
  );
};

export default HistoryPage;
