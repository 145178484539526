import { SET_USER_DATA } from './actions';

// @ts-ignore
export const userData = (state = null, {type, payload = {}} = {}) => {
    switch (type) {
        case SET_USER_DATA: 
            return payload;
        default: 
            return state;
    }
};