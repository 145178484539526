import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import HistoryPage from './../components/HistoryPage';
import { getContestHistory } from './../ducks/contests/actions';

interface HistoryState {
  getContests: Function
}

const HistoryContainer: React.FC<HistoryState> = (state, props) => {

  useEffect(() => {
    state.getContests();
  }, [])

  // @ts-ignore
  return (<HistoryPage  contests={state.contests} />)
};

const mapPropsToDispatch = dispatch => {
  return {
    getContests: () => dispatch(getContestHistory())
  }
};


const mapPropsToState = (state, props) => {
  const newProps = {
    contests: state.contests,
  };
  return newProps;
}


export default connect(
  mapPropsToState, 
  mapPropsToDispatch
)(HistoryContainer);
