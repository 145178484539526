export const GET_ADMIN_TRACK_FOR_DATA = "app/admin/GET_ADMIN_TRACK_FOR_DATA";
export const SET_ADMIN_TRACK_DATA = "app/admin/SET_ADMIN_TRACK_DATA";
export const PULL_ADMIN_TRACK_DATA = "app/admin/PULL_ADMIN_TRACK_DATA";
export const SET_PULLED_ADMIN_TRACK_DATA = "app/admin/SET_PULLED_ADMIN_TRACK_DATA";
export const PUT_ADMIN_TRACK_DATA = "app/admin/PUT_ADMIN_TRACK_DATA";
export const GET_ADMIN_TRACK_SOURCE = "app/admin/GET_ADMIN_TRACK_SOURCE";
export const SET_ADMIN_TRACK_SOURCE_DATA = "app/admin/SET_ADMIN_TRACK_SOURCE_DATA";
export const GET_ADMIN_TRACK_YOUTUBE_SOURCES = "app/admin/GET_ADMIN_TRACK_YOUTUBE_SOURCES";
export const SET_ADMIN_TRACK_YOUTUBE_SOURCES = "app/admin/SET_ADMIN_TRACK_YOUTUBE_SOURCES";
export const SET_ADMIN_YOUTUBE_SOURCE = "app/admin/SET_ADMIN_YOUBE_SOURCE";

export const getAdminTrackForData = () => ({
    type: GET_ADMIN_TRACK_FOR_DATA
});

export const setAdminTrackData = payload => ({
    type: SET_ADMIN_TRACK_DATA,
    payload
});

export const pullAdminTrackData = payload => ({
    type: PULL_ADMIN_TRACK_DATA,
    payload
});

export const setPulledAdminTrackData = payload => ({
    type: SET_PULLED_ADMIN_TRACK_DATA,
    payload
});

export const putAdminTrackData = payload => ({
    type: PUT_ADMIN_TRACK_DATA,
    payload
});

export const getAdminTrackSource = () => ({
    type: GET_ADMIN_TRACK_SOURCE
});

export const setAdminTrackSourceData = payload => ({
    type: SET_ADMIN_TRACK_SOURCE_DATA,
    payload
});

export const getAdminTrackYoutubeSources = payload => ({
    type: GET_ADMIN_TRACK_YOUTUBE_SOURCES,
    payload
});

export const setAdminTrackYoutubeSources = payload => ({
    type: SET_ADMIN_TRACK_YOUTUBE_SOURCES,
    payload
});

export const setAdminYoutubeSource = payload => ({
    type: SET_ADMIN_YOUTUBE_SOURCE,
    payload
});