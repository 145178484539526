//@ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import {
    IonGrid, 
    IonRow, 
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
  } from '@ionic/react';
  import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
  import firebase from 'firebase';



  

  
const StyledTitle = styled(IonCol)`
  text-align: center;
  font-weight: bold;
  font-family: 'Clarendon-Serial';
  font-size: 1.2em;
  margin-top:10%;
  color: #0f2070;
`;

const StyledFormElement = styled(IonCol)`
`;

const StyledIonLabel = styled(IonLabel)`
  color: #088C87 !important;
  font-weight: bold !important;
`;

const StyledIonInput = styled(IonInput)`
  color: #000 !important;
  border-color: #088C87 !important;

`;

const StyledIonItem = styled(IonItem)`

  margin-top:20px;

    & :focus {
      color: #088C87 !important;
    }
`;


const StyledStyledFirebaseAuth = styled(StyledFirebaseAuth)`
    width: 100%;
    backgrond: none;
`;

const LoginForm = (props)  => {


  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        'prompt': 'select_account'
      }
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: function(authResult) {
        props.LoginComplete({authResult, firebase});
        return false;
      }
    }
  };




  return (
    <IonGrid>
      <IonRow>
          <StyledTitle size="8" offset="2">SIGN IN/SIGN UP</StyledTitle>
      </IonRow>
      <IonRow>

          <StyledStyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>

      </IonRow>

    </IonGrid>
  );
};

export default LoginForm;
