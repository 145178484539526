
import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { getMe } from './../../actions/api';
import { 
    GET_ME,
    setUserData,
 } from './actions';

 function* handleGetUserData(data) {
    try{
        const userInfo = yield call(getMe, data.data.token);
        yield put({
            type: 'SET_USER_DATA',
            data: userInfo.data,
        });
        if (!data.data.home) {
            data.data.history.push('/')
        }
    }catch(e) {
        if (e.response && (e.response.status > 400 && e.response.status < 500 )) {
            if (!data.data.signup) {
                data.data.history.push('/login');
            }else{
                data.data.history.push('/signup');
            }
        }
    }
}

function* handleGetMe() {
    try{
        const userInfo = yield call(getMe, "");
        yield put(setUserData(userInfo.data));
    }catch(e) {

    }
}



export function* watchForGetUserData() {
    yield takeEvery('GET_USER_DATA', handleGetUserData);
}


export function* watchForGetMe() {
    yield takeEvery(GET_ME, handleGetMe);
}
