import React from 'react';
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonMenuButton, IonItem } from '@ionic/react';
import styled from 'styled-components';


const StyledWrapperDiv = styled.div`
    width: 100%;
    background: #0F2070;
    height: 110px;
    display: block;
    margin-top:20px;
    @media (max-width: 600px) {
        padding-left: 10%;
        padding-top:4%;
    }
    @media (min-width: 601px) {
        padding-left 4%;
        padding-top: 1%;
    }
`;

const StyledSpanTitle = styled.span`
    color: white;
    font-weight: bold;
    font-size: 14px;
`;

const StyledArt = styled.img`
    height: 50px;
    border: 1px solid white;
    margin-top: 5px;
    float: left;
`;


const StyledAlbumTitle = styled.span`
    color: White;
    padding-left: 40px;
    margin-left: 20px;
    margin-top: 0px;
    padding-top: 5px;
    display: block;
`;

interface FeaturedProps {
    title: string
    artist: string
    artwork: string
    playUrl: string
}

interface CompProps {
    featured: FeaturedProps
}

const Featured: React.FC<CompProps> = ( props ) => {

    return (<>
        <StyledWrapperDiv>
            <StyledSpanTitle>Featured This Week:</StyledSpanTitle><br />
            <StyledArt src={props.featured.artwork}/>
            <StyledAlbumTitle>{props.featured.title} - {props.featured.artist}</StyledAlbumTitle>
        </StyledWrapperDiv>
        <IonItem> <audio controls style={{width: '100%', backgroundColor: 'white'}} src={props.featured.playUrl}>
        </audio></IonItem></>
    )



};



export default Featured;