import React, {useEffect} from 'react';
import Styled from 'styled-components';

import PickCollection from './PickCollection';

const StyledWindow = Styled.div`
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    height: calc(100vh - 235px);
    
`;

interface PlaylistPageProps {
    getContestTracks: Function
    contestTracks: any
    contestId: string
    trackData: any
    getTrackData: Function
    contest: any
    clearTrackData: Function
}


function findAndMapPoints(contestTracks, contest) {
    return contestTracks.map(track => {
        const trackData = track;
        contest.ranking.forEach(rank => {
            rank.points.tracksScore.forEach(score => {
                if (track.track_id === score.trackId) {
                    trackData.score = score.score;
                }
            });
        });
        return trackData;
    }).sort((a,b) => b.score - a.score);
}



const PlaylistPage: React.FC<PlaylistPageProps> = props => {
    useEffect(() => {
        if (!props.contestTracks) {
            props.getContestTracks(props.contestId);
        }
    }, []);
    if (props.contestTracks) {
        return <StyledWindow><PickCollection 
            trackData={props.trackData}
            getTrackData={props.getTrackData}
            contest={props.contest}
            playlist={findAndMapPoints(props.contestTracks, props.contest)}
            clearTrackData={props.clearTrackData}
        /></StyledWindow>
    }else{
        return <></>;
    }
};



export default PlaylistPage;