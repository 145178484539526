import React from 'react';
import { 
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonContent,
} from '@ionic/react';
import styled from 'styled-components';
import ContestElement from './ContestElement';


const StyledYourContestTitle = styled.b`
    color: #0F2070;
    font-size: 18px;
    margin-left: 30px;
    margin-top: 30px;
    font-family: 'Clarendon-Serial';
    display: block;
`;


const StyledContestWrapper = styled.div`
`;


const StyledElementWrapper = styled(IonGrid)`
    width: 100%;
    display: block;
`;

const StyledIonColTitle = styled(IonCol)`
    color: #088C87;
`;

const StyledIonTitleRow = styled(IonRow)`
    border-bottom: 1px solid #088C87;
`;

interface ContestProp {
    name: String
    rank: number
    id: string
    started: boolean
}

interface ContestListProps {
    contestListTitle: any
    contests: Array<ContestProp>
    history?: boolean
}

const ContestList: React.FC<ContestListProps> = props => {

    return (<StyledContestWrapper >
        <StyledYourContestTitle>
            {props.contestListTitle ? props.contestListTitle : "Your Contests"}
        </StyledYourContestTitle>
        <StyledElementWrapper>
            <StyledIonTitleRow>
                <StyledIonColTitle size="4" offset="1">Contest Name</StyledIonColTitle>
                {/* <StyledIonColTitle offset="2" size="2">Points</StyledIonColTitle> */}
                <StyledIonColTitle size="2" offset="3">Status</StyledIonColTitle>
            </StyledIonTitleRow>
            {props.contests.map(el => {
                return <ContestElement key={el.id} contestName={el.name} points="500" rank={el.rank} contestId={el.id} started={el.started} complete={props.history}/>
            })}
        </StyledElementWrapper>
        


    </StyledContestWrapper>);



};



export default ContestList;