
import { put, takeLatest, call } from 'redux-saga/effects'
import { 
    searchContest,
    searchMusic,
} from './../../actions/api';

import { 
    SEARCH_CONTEST,
    SEARCH_MUSIC,
    setSearchContestResults,
    setSearchMusicResults,
} from './actions';

 function* handleSearchContest(payload) {
    try{
        const searchValue = payload.payload.formData;
        const searchData = yield call(searchContest, searchValue);
        yield put(setSearchContestResults(searchData.data));
        
    }catch(e) {
        
    }
}

const cleanMusicResults = resultsArray => {
   return resultsArray.reduce((acc, current) => {
        const x = acc.find(
            item => item.artistName === current.artistName && item.trackName === current.trackName
        );
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
};


function* handleSearchMusic(payload) {
    try{
        if (payload.payload) {
            const searchResults = yield call(searchMusic, payload.payload);
            const resultsData = searchResults.data.results;
            yield put(setSearchMusicResults(cleanMusicResults(resultsData)));
        }else{
            yield put(setSearchMusicResults([]));

        }
    }catch(e) {

    }
}



export function* watchForSearchContest() {
    yield takeLatest(SEARCH_CONTEST, handleSearchContest);
}

export function* watchForSearchMusic() {
    yield takeLatest(SEARCH_MUSIC, handleSearchMusic);
}