

export const SHOW_ERROR = "app/generic/SHOW_ERROR";
export const showError = payload => ({
    "type": SHOW_ERROR,
    payload
});

export const SET_ERROR_MESSAGE = "app/generic/SET_ERROR_MESSAGE";
export const setErrorMessage = payload => ({
    "type": SET_ERROR_MESSAGE,
    payload
});
