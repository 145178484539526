//@ts-nocheck
import {
    SET_WATCHLIST
} from './actions';
  

// @ts-ignore
export function watchlist(state = [], { type, payload = [] } = {}) {
    switch (type) {
        case SET_WATCHLIST:
            return payload

        default: 
            return state;

    }
};