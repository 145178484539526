import React from 'react';
import styled from 'styled-components';
import {
    IonAvatar,
    IonImg,
    IonButton
} from '@ionic/react';
import { removeFromContest } from '../../actions/api';


const StyledUserBlock = styled.div`
`;

const StyledUserName = styled.p`
    margin-left: 10px;
    float: left;
    font-family: "Franklin Gothic Medium", "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif;
`;

const StrokeStyle = styled.span`
    font-weight: bold;
`;

const StyledRank = styled.span`
    background: #0f2070;
    border-radius: 0.9em;
    -moz-border-radius: 0.9em;
    -webkit-border-radius: 0.9em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.7em; 
    float: left;
    margin-top: .9em;
`;
const StyledWinnerRank = styled.span`
    background: #088C87;
    border-radius: 0.9em;
    -moz-border-radius: 0.9em;
    -webkit-border-radius: 0.9em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.7em; 
    float: left;
    margin-top: .9em;
`;


const StyledIonAvatar = styled(IonAvatar)`
    height: 40px;
    width: 40px;
    float: left;
    margin-top:3px;
    margin-right: 5px;
`;

interface LeaderBoarduserProps {
    firstName: String,
    lastName: String
    rank?: number
    me: Boolean
    admin?: Boolean
    contest?: any
    removeFromContest: Function
    id: string
}


const LeaderboardUserComponent: React.FC<LeaderBoarduserProps> = props => {
    return (
        <StyledUserBlock>
        {props.me ? <StrokeStyle>
            {props.rank && props.rank > 3 ? <StyledRank>{props.rank}</StyledRank> : <></>}
            {props.rank && props.rank <= 3 ? <StyledWinnerRank>{props.rank}</StyledWinnerRank> : <></>}
            <StyledUserName>{props.firstName} {props.lastName[0]}.</StyledUserName>
            {props.admin ? <IonButton style={{marginLeft: '10px'}} slot="start" onClick={u => {removeFromContest(props.contest.id, props.id)}}>X</IonButton>: <></>}
            </StrokeStyle>
        : <>
            {props.rank && props.rank > 3 ? <StyledRank>{props.rank}</StyledRank> : <></>}
            {props.rank && props.rank <= 3 ? <StyledWinnerRank>{props.rank}</StyledWinnerRank> : <></>}
            <StyledUserName>{props.firstName} {props.lastName[0]}.</StyledUserName>
            {props.admin ? <IonButton  style={{marginLeft: '10px'}} slot="start" onClick={u => {removeFromContest(props.contest.id, props.id)}}>X</IonButton>: <></>}
        </>
        }
        </StyledUserBlock>
    );
};



export default LeaderboardUserComponent;