import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IonImg, IonSearchbar, IonItem } from '@ionic/react';
import SearchResults from './SearchResults';
import { debounce } from 'lodash';

const StyledLogoImg = styled(IonImg)`
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
`;



const ContestSearchWindow = props  => {
    useEffect(() => {

    }, []);

  return (
    <div>
        <StyledLogoImg src="https://jazzdaq-public.s3.us-east-2.amazonaws.com/Jazzdaq_logo_wordmark.svg"/>
        <IonItem>
            <IonSearchbar 
                animated={true}
                onIonChange={e => props.setSearchVal(e.detail.value!)}
            />
        </IonItem>
        {props.searchResults.results ? <SearchResults results={props.searchResults.hits} /> : <></>}
    </div>
  );
};

export default ContestSearchWindow;
