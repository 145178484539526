import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getWatchlistResult as getWatchlistSelector } from './../ducks/watchlist/selectors';
import { getWatchlist, addToWatchlist, removeFromWatchlist } from './../ducks/watchlist/actions';
import  WatchlistPage from './../components/Watchlist/WatchlistPage';
import {
    searchMusic
} from './../ducks/search/actions';
import {
    getMusicSearchResults as getMusicSearchResultsSelector
 } from './../ducks/search/selectors';
 import { 
    getTrackDataResults as getTrackDataResultsSelector,
} from './../ducks/contests/selectors';
import { 
    getTrackData,
    setTrackData,
 } from './../ducks/contests/actions';
const WatchlistContainer: React.FC = (state:any, props) => {

  useEffect(() => {
      state.getWatchlist();
      return () => {}
  }, [props.watchlist])

  // @ts-ignore
  return (<WatchlistPage 
    searchMusic={state.searchMusic}
    musicResults={state.musicResults || []}
    addToWatchlist={state.addToWatchlist}
    getTrackData={state.getTrackData}
    clearTrackData={state.clearTrackData}
    trackData={state.trackData}
    remove={state.remove}
    watchlist={state.watchlist}
  />)
};

const mapPropsToDispatch = dispatch => {
  return {
    getWatchlist: () => dispatch(getWatchlist()),
    searchMusic: data => dispatch(searchMusic(data)),
    addToWatchlist: trackId => dispatch(addToWatchlist(trackId)),    
    getTrackData: id => dispatch(getTrackData(id)),
    clearTrackData: () => dispatch(setTrackData(null)),
    remove: id => dispatch(removeFromWatchlist(id))
}
};


const mapPropsToState = (state, props) => {
  return {
    musicResults: getMusicSearchResultsSelector(state),
    watchlist: getWatchlistSelector(state),
    trackData: getTrackDataResultsSelector(state),
};
}


export default connect(
  mapPropsToState, 
  mapPropsToDispatch
)(WatchlistContainer);
