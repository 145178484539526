
import { put, takeLatest, call, all, select } from 'redux-saga/effects'
import { getFeatured, adminSetFeatured } from './../../actions/api';
import { setFeaturedData, GET_FEATURED_DATA, SAVE_FEATURED_DATA, saveFeaturedData } from './actions';

 function* handleGetFeatured(data) {
    try{
        const userInfo = yield call(getFeatured, '');
        yield put(setFeaturedData(userInfo.data[0]));
        
    }catch(e) {



        console.error(e);

        if (e.response && (e.response.status > 400 && e.response.status < 500 )) {
            if (!data.data.signup) {
                data.data.history.push('/login');
            }else{
                data.data.history.push('/signup');
            }
        }
    }
}


function* handleSaveFeatured(data) {
    try{
        const { payload } = data;
        yield call(adminSetFeatured, payload);
        window.location.href="/admin";
    }catch(e) {
        console.error(e);
    }
}


export function* watchForFeatures() {
    yield takeLatest(GET_FEATURED_DATA, handleGetFeatured);
    yield takeLatest(SAVE_FEATURED_DATA, handleSaveFeatured);
}
