import React from 'react';
import { Route } from 'react-router-dom';
import {
  IonIcon,
  IonLabel,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonItem
} from '@ionic/react';
import {
    star,
    musicalNote,
    logoYoutube
} from 'ionicons/icons';

import styled from 'styled-components';


const StyledTabWrapper = styled.span`
  & ion-tab-bar {
    height: 10%;
    border-top:1px solid #088C87;

    & ion-tab-button {
      & ion-icon {
        font-size: 3em;
      }

      & ion-label {
        color: #088C87;
        font-weight: bold;
      }
    }

    & .tab-selected {
       border-bottom: 5px solid #F8B302;
    }

  }
`;
interface NavProps {
}

const AdminTabs: React.FC<NavProps> = props => {

    return (<StyledTabWrapper>
      <IonItem>
      <a href='/admin?p=feature'>Featured</a>&nbsp;&nbsp;&nbsp;
      <a href='/admin?p=source'>Track Source</a>&nbsp;&nbsp;&nbsp;
      <a href='/admin?p=track'>Data Collect</a>
      </IonItem>
      <IonTabs>
        <IonRouterOutlet>
        
        </IonRouterOutlet>
        
          <IonTabBar slot="bottom">
            <IonTabButton tab="featured" href="/admin?p=feature" >
              <IonIcon icon={star}/>
              <IonLabel>Featured</IonLabel>
            </IonTabButton>
            <IonTabButton tab="youtube_source" href="/admin?p=source" >
              <IonIcon icon={logoYoutube} />
              <IonLabel>Track Source</IonLabel>
            </IonTabButton>

            <IonTabButton tab="track_data" href="/admin?p=track" >
              <IonIcon icon={musicalNote} />
              <IonLabel>Track-Data-Collect</IonLabel>
            </IonTabButton>
            
          </IonTabBar>
      </IonTabs>
    </StyledTabWrapper>)
};

export default AdminTabs;