import React from 'react';
import { Route } from 'react-router-dom';
import {
  IonIcon,
  IonLabel,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
} from '@ionic/react';

import { cog } from 'ionicons/icons';

import styled from 'styled-components';


const StyledTabWrapper = styled.span`
  & ion-tab-bar {
    height: 10%;
    border-top:1px solid #088C87;

    & ion-tab-button {
      & ion-icon {
        font-size: 3em;
      }

      & ion-label {
        color: #088C87;
        font-weight: bold;
      }
    }

    & .tab-selected {
       border-bottom: 5px solid #F8B302;
    }

  }
`;
interface NavProps {
  selected: number
  admin?: boolean
}

const BottomNavTabs: React.FC<NavProps> = ({selected, admin=false}) => {

    return (<StyledTabWrapper>
      <IonTabs>
        <IonRouterOutlet>
        
        </IonRouterOutlet>
        
          <IonTabBar slot="bottom">
            <IonTabButton tab="main-stage" href="/main-stage" >
              <IonIcon src="/assets/icon/main_stage_icon.svg"/>
              <IonLabel>Main Stage</IonLabel>
            </IonTabButton>
            <IonTabButton tab="history" href="/history">
              <IonIcon src="/assets/icon/my_contest_icon.svg" />
              <IonLabel>History</IonLabel>
            </IonTabButton>
            <IonTabButton tab="create-contest" href="/create-contest" hidden={!admin}>
              <IonIcon src="/assets/icon/create_contest_icon.svg" />
              <IonLabel>Create Contest</IonLabel>
            </IonTabButton>
            <IonTabButton tab="attend" href="/contest-search">
              <IonIcon src="/assets/icon/attend_icon.svg" />
              <IonLabel>Attend</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Admin" href="/admin" hidden={!admin}>
              <IonIcon icon={cog} />
              <IonLabel>Admin</IonLabel>
            </IonTabButton>
          </IonTabBar>
      </IonTabs>
    </StyledTabWrapper>)
};

export default BottomNavTabs;