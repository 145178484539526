import { createSelector } from 'reselect';


export const getSearchResultState = ({ contestSearchResults = [] }) => contestSearchResults;

export const getSearchResults = createSelector(
    [getSearchResultState],
    results => results
);


export const getMusicSearchResultState = ({ musicSearchResults = [] }) => musicSearchResults;

export const getMusicSearchResults = createSelector(
    [getMusicSearchResultState],
    results => results
);