export const GET_ME = "app/user/GET_ME";
export const SET_USER_DATA = "app/user/SET_USER_DATA";

export const getMe = payload => ({
    type: GET_ME,
    payload
});


export const setUserData = payload => ({
    type: SET_USER_DATA,
    payload
});