import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent, IonItem, IonCardContent } from '@ionic/react';

import Header from './../Header';
import BottomNavTabs from '../BottomNavTabs';
import SearchComponent from '../Shared/SearchComponent';
import WatchlistSearch from './WatchlistSearch';
import Watchlist from './Watchlist';

const StyledPage = styled(IonContent)`
  overflow: scroll;
  height: calc(100% - 148px);
  -webkit-overflow-scrolling: auto;
`;




const WatchlistPage = props  => {
    useEffect(() => {

    }, []);
    console.log('watchlist page props', props);
  return (
    <IonContent>
        <Header menu={true} title="My Watchlist"/>
        <StyledPage>
            <WatchlistSearch
                searchMusic={props.searchMusic}
                musicResults={props.musicResults}
                addToWatchlist={props.addToWatchlist}
            />
            <Watchlist 
                getTrackdata={props.getTrackData}
                clearTrackData={props.clearTrackData}
                trackData={props.trackData}
                watchlist={props.watchlist}
                remove={props.remove}
            />
        </StyledPage>
        <BottomNavTabs selected={1} />
    </IonContent>
  );
};

export default WatchlistPage;
