import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
  IonContent,
} from '@ionic/react';

import Header from './../Header';
import AdminTabs from './AdminTabs';
import TrackDataWindow from './TrackDataWindow';
import SourceWindow from './SourceWindow';
import FeatureForm from './FeatureForm';
import { musicSearchResults } from '../../ducks/search/reducers';



const StyledIonContent = styled(IonContent)`

`;

interface AdminPageProps {
    track: string
    setTrack: Function
    page: string
    trackResults: any
    pullAdminTrackData: Function
    putAdminTrackData: Function
    setAdminYoutubeSource: Function
    saveFeaturedData: Function
    trackViewDataResults: any
    trackSourceData: any
    trackYoutubeData: any
    featured: any
    musicResults: any
    searchMusic: Function
}

const AdminPage: React.FC<AdminPageProps> = props  => {
  useEffect(() => {
  })


  return (
    <StyledIonContent>
        <Header menu={true} title="Admin"/>
        {props.page !== 'track' || <TrackDataWindow 
          tracks={props.trackResults} 
          pullAdminTrackData={props.pullAdminTrackData}
          trackViewDataResults={props.trackViewDataResults}
          putAdminTrackData={props.putAdminTrackData}
          />}

        {props.page !== 'source' || <SourceWindow 
          trackSourceData={props.trackSourceData}
          trackYoutubeData={props.trackYoutubeData}
          track={props.track}
          setAdminYoutubeSource={props.setAdminYoutubeSource}
        />}

        {props.page !== 'feature' || <FeatureForm
          featured={props.featured}  
          saveFeaturedData={props.saveFeaturedData}
          searchMusic={props.searchMusic}
          musicResults={props.musicResults}
      
        />}

        {props.musicResults.length || <AdminTabs />}
    </StyledIonContent>
  );
};

export default AdminPage;
