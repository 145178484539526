import { createSelector } from 'reselect';


export const getErrorMessageState = ({ errorMessage = '' }) => errorMessage;
export const getErrorMessageStateResult = createSelector(
    [getErrorMessageState],
    results => results
);
export const getError = ({ showErrorMessage = false }) => showErrorMessage;
export const getErrorStateResult = createSelector(
    [getError],
    results => results
);
