import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonItem,
    IonToast,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,    
} from '@ionic/react';


const StyledContestTitle = styled(IonCol)`
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    font-family: 'Clarendon-Serial';
    color: #0f2070;
`;

const StyledIonRow = styled(IonRow)`
    margin-top: 25%;
`;

const StyledIonInput = styled(IonInput)`
    width: 100%;
    border-bottom: 1px solid #088C87;
    color: #088C87;
    font-weight: Bold;
    text-align: center;
`;

const StyledIonButton = styled(IonButton)`
    width: 100%;
    font-family: 'Clarendon-Serial';
    height: 50px;
    font-size: .8em;
    margin-top: 10%;
    z-index: 9999;
`;


const JoinCodeForm = props  => {
    const [contestCode, setContestCode] = useState("");
    const [contestSubmitDisabled, setContestSubmitDisabled] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const setAndUpdateContestCode = (val: string) => {
        if (val.length <9) {
            setContestCode(val.toUpperCase());
        }

        if(val.length === 8) {
            setContestSubmitDisabled(false);
        }else{
            setContestSubmitDisabled(true);
        }
    }

    const SubmitContestCode = () => {
        props.getContest(contestCode);
    };



    useEffect(() => {
        if (props.contest.error) {
            setErrorMessage(props.contest.error);
            setShowError(true);
        }
    });

  return (
    <IonGrid>
        <StyledIonRow>
            <StyledContestTitle color="primary" offset="1" size="10">
                TYPE ACCESS CODE TO JOIN A FRIEND'S CONTEST
            </StyledContestTitle>
        </StyledIonRow>

        <IonRow>
            <IonCol offset="1" size="10">
                <StyledIonInput 
                    placeholder="Enter Contest Code" 
                    value={contestCode}
                    maxlength={8}
                    onIonChange={e => setAndUpdateContestCode(e.detail.value!)}
                    />
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol offset="2" size="8">
                <IonItem>
                    <StyledIonButton 
                        disabled={contestSubmitDisabled}
                        onClick={SubmitContestCode}
                    >Submit</StyledIonButton>
                </IonItem>
            </IonCol>
        </IonRow>

        <IonToast
            color="danger"
            isOpen={showError}
            onDidDismiss={() => {
                props.contest.error = undefined;
                setShowError(false);
            }}
            message={errorMessage}
            duration={2000}
        />

    </IonGrid>
  );
};

export default JoinCodeForm;
