import React from 'react';
import Styled from 'styled-components';


const StyledHeader = Styled.div`
    width: 100%;
    background: rgb(238,238,238);
    background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 100%);
    height: 30px;
    color: #0f2070;
    font-family: 'Clarendon-Serial';
    text-align: center;
    font-weight: bold;
    padding-bottom 20px;
    font-size: 15px;
`;

interface ContestHeaderProps {
    title: string
}

const ContestHeader: React.FC<ContestHeaderProps> = props => {
    const { title } = props;
    return <StyledHeader>{title}</StyledHeader>;
};



export default ContestHeader;