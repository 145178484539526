import { REMOVE_FROM_CONTEST } from "../contests/actions";


export const GET_WATCHLIST = "app/watchlist/GET_WATCHLIST";
export const getWatchlist = () => {
    return {
        "type": GET_WATCHLIST
    };
};

export const SET_WATCHLIST = "app/watchlist/SET_WATCHLIST";
export const setWatchlist = payload => ({
    "type": SET_WATCHLIST,
    payload
});


export const ADD_TO_WATCHLIST = "app/watchlist/ADD_TO_WATCHLIST";
export const addToWatchlist = payload => ({
    "type": ADD_TO_WATCHLIST,
    payload
});

export const REMOVE_FROM_WATCHLIST = "app/watchlist/REMOVE_FROM_WATCHLIST";
export const removeFromWatchlist = payload => ({
    "type": REMOVE_FROM_WATCHLIST,
    payload
});