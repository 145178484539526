//@ts-nocheck
import {
    SET_CONTEST_DATA,
    SET_SINGLE_CONTEST_DATA,
    CREATE_CONTEST_RESPONSE,
    JOIN_CONTEST_SUCCESS,
    JOIN_CONTEST_FAILURE,
    SET_TRACK_DATA,
    SET_PICK_DATA,
    SET_CONTEST_TRACKS,
  } from './actions';
  
const INITIAL_STATE = [];
const INITIAL_CONTEST_STATE = {
    contestData: {},
    error: undefined
};

// @ts-ignore
export function contests(state = INITIAL_STATE, { type, payload = {} } = {}) {
    switch (type) {
        case SET_CONTEST_DATA:
            return Object.values(payload);

        default: 
            return state;

    }
};

// @ts-ignore
export function contest(state = INITIAL_CONTEST_STATE, { type, payload = {} } = {}) {
    switch (type) {
        case SET_SINGLE_CONTEST_DATA: 
            return payload;

        default: 
            return state;
    }
};

// @ts-ignore
export const createContestResults = (state = null, {type, payload = {}} = {}) => {
    switch (type) {
        case CREATE_CONTEST_RESPONSE: 
            return payload;
        default: 
            return state;
    }
};

// @ts-ignore
export const joinContestResults = (state = {error: false, errorMessage: ''}, {type, payload = {}} = {}) => {
    switch (type) {
        case JOIN_CONTEST_SUCCESS:
            if (payload) {
                return {
                    error: true,
                    errorMessage: `Unable to join contest`
                };
            }else{
                return {
                    error: false,
                    errorMessage: '',
                };
            }
        default: 
            return state;
    }
};

export const trackData = (state = null, {type, payload=null} = {}) => {
    switch(type) {
        case SET_TRACK_DATA:
            return payload;
        default: 
            return state;
    }

};

export const pickData = (state = null, {type, payload=null} = {}) => {
    switch(type) {
        case SET_PICK_DATA:
            return payload;
        default:
            return state;
    }
}

export const contestTrackData = (state = null, {type, payload=null} = {}) => {
    switch(type) {
        case SET_CONTEST_TRACKS:
            return payload;
        default:
            return state;
    }
};