import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent } from '@ionic/react';

import Header from './../Header';
import BottomNavTabs from '../BottomNavTabs';
import Featured from '../Featured';
import ContestList from '../ContestList';

const StyledIonContent = styled(IonContent)`

`;

const StyledPage = styled.div`
  overflow: scroll;
  height: calc(100% - 148px);
  -webkit-overflow-scrolling: auto;
`;




const HomePage = props  => {
    useEffect(() => {

    }, []);
    const admin = props.userData?.admin;
  return (
    <StyledIonContent>
        <Header menu={true} title="Main Stage"/>
        <StyledPage>
          <Featured featured={props.featured}/>
          <ContestList contestListTitle="Your Contests:" contests={props.contests}/>
        </StyledPage>
        <BottomNavTabs selected={1} admin={admin}/>
    </StyledIonContent>
  );
};

export default HomePage;
