import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { signup } from './../actions/api';

import { getContestData } from './../ducks/contests/actions';


const getJwtToken = async (data) => {
    return await data.firebase.auth().currentUser.getIdToken();
            // .then(idToken => resolve(idToken))
};


function* handleLoginComplete(data) {
    // data.data.history.push('/')
    try{
        const token = yield getJwtToken(data.data);
        yield put({
            type: 'GET_USER_DATA',
            data: {...data.data, token, signup: true},
        });
    }catch(e) {
        console.log('errrrr', e);
    }
}

function* handleSignup(data) {
    try{
        const user = yield call(signup, data.data.user);
        yield put({
            type: 'SET_USER_DATA',
            data: user
        });
        data.data.history.push('/');
    }catch(e) {
        console.error(e);
    }
}

export function* watchForSignup() {
    yield takeEvery('SIGNUP', handleSignup);
}

export function* watchForLogin() {
    yield takeEvery('LOGIN_COMPLETE', handleLoginComplete);
}
