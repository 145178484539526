
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    getErrorMessageStateResult,
    getErrorStateResult,
} from './../ducks/generic/selectors';
import { showError } from './../ducks/generic/actions';
import {
    IonToast
} from '@ionic/react';



const Error = (props, state) => {

 return (<IonToast
    color="danger"
    isOpen={props.showErrorMessage}
    onDidDismiss={props.close}
    message={props.errorMessage}
    duration={3000}
/>);
}


const mapPropsToDispatch = (dispatch, props) => {
    return {
        close: () => dispatch(showError(false))
    }
};
  
  
const mapPropsToState = state => ({
    showErrorMessage: getErrorStateResult(state),
    errorMessage: getErrorMessageStateResult(state)
});
  
  
export default connect(
    mapPropsToState, 
    mapPropsToDispatch
  )(Error);