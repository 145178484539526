import React from 'react';
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonMenuButton } from '@ionic/react';
import styled from 'styled-components';
import Logo from './Logo';

const TitleStyle = styled.div`
    margin-top: 40px;
    padding: 0px;
    font-weight: bold;
    font-family: 'Clarendon-Serial'
`;

const HeaderStyle = styled.div`
    & .header-md::after {
        display: none;
    }
`;


const StyledIonButtons = styled(IonButtons)`
    float: right;
    & ion-menu-button {
        font-size: 40px;
        margin-top: -10px;
    }
`;



const Header: React.FC<any> = ( props ) => {

    return (
        <HeaderStyle>
            <IonHeader >
                <IonToolbar>
                    <IonTitle size="large" color="primary" class="ion-text-center">
                        <Logo />
                        <TitleStyle alt={props.title}>{props.title ? props.title.substr(0, 16) : "Jazzdaq"}

                        {props.menu ? <StyledIonButtons>
                            <IonMenuButton color="secondary" auto-hide="false"></IonMenuButton>
                        </StyledIonButtons> : <></>}
                        </TitleStyle>
                    </IonTitle> 
                    
                </IonToolbar>
            </IonHeader>
        </HeaderStyle>
    )



};



export default Header;