import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { get } from 'lodash';
import LoginPage from '../components/SignUpIn/LoginPage';
import { signUpSubmit } from './../actions/auth';
import { getNewUserStateSelector } from './../ducks/auth/selectors';

const SignUpContainer: React.FC = state => {
    const dispatch = useDispatch();
    const history = useHistory();
    const submitSignup = userData => dispatch(signUpSubmit(userData, history));
    const userData = {
      name: get(state, 'newUserData.displayName'),
      email: get(state, 'newUserData.email')
    };

  return (<LoginPage submitSignup={submitSignup} signup={true} userData={userData}/>)
};

const mapPropsToState = state => ({ 
    newUserData: getNewUserStateSelector(state),
});


export default connect(mapPropsToState)(SignUpContainer);
