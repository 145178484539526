import React from 'react';
import styled from 'styled-components';
import {
    IonRow,
    IonCol,
} from '@ionic/react';


const StyledIonRow = styled(IonRow)`
    border-bottom: 1px solid #088C87;
    color: #088C87;
    font-family: 'Domaine-Regular';
    font-weight: 200;
`;


interface ListProps {
    index?: number,
    content: Array<{
        size: string,
        content: string
    }>
};


const ListHeaderElement: React.FC<ListProps> = props => {

    return (
        <StyledIonRow key={`rowkey${props.index}`}>
            {props.content.map((head, index) => (<IonCol 
                    key={`colkey${index}`} 
                    size={head.size}
                >{head.content}</IonCol>))}
        </StyledIonRow>
    )
};


export default ListHeaderElement;