import React from 'react';
import { connect, useDispatch } from 'react-redux';
import CreateContestPage from './../components/CreateContest/CreateContestPage';
import { getContestData, createContest } from './../ducks/contests/actions';
import { getCreateContestResults } from './../ducks/contests/selectors';

const CreateContestContainer: React.FC = (state, props) => {
  // @ts-ignore
  return (<CreateContestPage 
    //@ts-ignore
    createContest={state.createContest} 
    //@ts-ignore
    createContestResults={state.createContestResults} 
    //@ts-ignore
    gotoContest={state.gotoContest}
    />)
};

const mapPropsToDispatch = (dispatch, props) => {
  return {
    createContest: data => {
      dispatch(createContest({formData: data, history: props.history}));
    },
    gotoContest: id => {
      props.history.push(`/contest/${id}`);
    }
  };
};


const mapPropsToState = state => ({ createContestResults: getCreateContestResults(state)})


export default connect(
  mapPropsToState, 
  mapPropsToDispatch
)(CreateContestContainer);
