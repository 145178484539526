
import { put, takeLatest, call } from 'redux-saga/effects'
import { 
    getWatchlist,
    addToWatchlist,
    removeFromWatchlist,
} from './../../actions/api';
import { 
  GET_WATCHLIST,
  ADD_TO_WATCHLIST,
  REMOVE_FROM_WATCHLIST,
  setWatchlist,
} from './actions';


 function* handleGetWatchlist() {
    try{
        const watchlistData = yield call(getWatchlist);
        yield put(setWatchlist(watchlistData.data));
    }catch(e) {
       
    }
}

function* handleAddWatchlist(data) {
    try{
        const watchlistData = yield call(addToWatchlist, data.payload);
        yield put(setWatchlist(watchlistData.data));
    }catch(e) {

    }
}

function* handleRemoveWatchlist(data) {
    try{
        const watchlistData = yield call(removeFromWatchlist, data.payload);
        yield put(setWatchlist(watchlistData.data));
    }catch(e){}
}


export function* watchForWatchlist() {
    yield takeLatest(GET_WATCHLIST, handleGetWatchlist);
    yield takeLatest(ADD_TO_WATCHLIST, handleAddWatchlist);
    yield takeLatest(REMOVE_FROM_WATCHLIST, handleRemoveWatchlist);
}
