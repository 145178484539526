
const UserReducer = (state = {}, action) => {

    switch(action.type) {
        case 'LOGIN_COMPLETE':
            return action.data;
            break;
        case 'SET_USER_DATA':
            return action.data;
            break;
        default: 
            return state;
            break;
    };

}



export default UserReducer;