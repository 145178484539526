
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonMenuButton,
    IonButton,
    IonLabel,
    IonNav,
    IonItemDivider,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';

import { getUserStateSelector } from './../ducks/auth/selectors';
import { getMe } from './../ducks/auth/actions';

const StyledIonContent = styled(IonContent)`

`;

const StyledHeaderImg = styled.img`
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    margin-bottom: 5%;
    display: block;
`

const StyledIonHeader = styled(IonHeader)`
    &:after{
        display: none;
    }
`;

const StyledIonButton = styled(IonButton)`
    width: 100%;
    height: 50px;
    border-bottom:0px;
    margin: 0px;

    

    & > #shadow-root.button-inner {
        justify-content: flex-start !important;
    }

 

`;

const StyledIonItem = styled(IonItem)`
    padding: 0;
    `;

const StyledIonList = styled(IonList)`
    padding: 0;
`;

const StyledIonCol = styled(IonCol)`
    color: #43ad3b;
    font-weight: bold;
`;

const StyledIonButtonAddFunds = styled(IonButton)`
    width: 100%;
    height: 30px;
`;

const StyledHr = styled(IonRow)`
    background-color: #088C87;
    height: 1px;

`;

interface MenuProps {
    user: any
    getMe: Function
}

const Menu: React.FC<MenuProps> = props => {

    useEffect(() => {
        // @ts-ignore
        // @ts-ignore
        if (props.history.location.pathname !== '/login') {
            props.getMe();
        }
        return () => {}
    }, [])

 return (
    <IonMenu side="end" menuId="first" content-id="main-content" tyle="push">
      <StyledIonHeader>
        <IonToolbar color="primary">
          <StyledHeaderImg src="/assets/icon/Jazzdaq_logo_wordmark_white.svg" />
        </IonToolbar>
      </StyledIonHeader>
      <StyledIonContent color="primary">
        <IonGrid>
            {/* <IonRow>
                <IonCol><b>My Wallet</b></IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="10">Balance 
                </IonCol>
                <StyledIonCol>$0</StyledIonCol>
            </IonRow>
            <IonRow>
                <IonCol size="8" offset="2">
                    <StyledIonItem color="primary" href="/wallet">
                        <StyledIonButtonAddFunds color="secondary" disabled={true}>
                            Manage Wallet
                        </StyledIonButtonAddFunds>
                    </StyledIonItem>
                </IonCol>
            </IonRow>

            <StyledHr />
            <IonRow>
                <IonCol size="4" offset="1">
                    <IonItem color="primary" href="/settings" type="button" disabled={true}>
                        <StyledIonButton color="primary" slot="start" disabled={true}>Settings</StyledIonButton></IonItem>
                </IonCol>
            </IonRow> */}


            {/* <IonRow>
                <IonCol size="5" offset="1">
                    <IonItem color="primary" href="/watchlist" type="button">
                        <StyledIonButton color="primary" slot="start">Watchlist</StyledIonButton></IonItem>
                </IonCol>
            </IonRow> */}



            <IonRow>
                <IonCol size="4" offset="1">
                    <IonItem color="primary" href="/logout" type="button">
                        <StyledIonButton color="primary" slot="start">Logout</StyledIonButton></IonItem>
                </IonCol>
            </IonRow>
        </IonGrid>
      </StyledIonContent>
    </IonMenu>);
}


const mapPropsToDispatch = (dispatch, props) => {
    return {
        getMe: token => dispatch(getMe(token)),
        history: props.history,
    };
  };
  
  
  const mapPropsToState = state => ({
      user: getUserStateSelector(state),
  });
  
  
export default connect(
    mapPropsToState, 
    mapPropsToDispatch
  )(Menu);