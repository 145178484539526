import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AttendPage from './../components/Attend/AttendPage';
import { getContest } from './../ducks/contests/actions';

const AttendContainer: React.FC = (state, props) => {

  useEffect(() => {
    
  }, [])
    //@ts-ignore
    return (<AttendPage joinContest={state.joinContest} contest={state.contest}/>)
};

const mapPropsToDispatch = (dispatch, props) => {
  return {
    joinContest: data => {
      dispatch(getContest({formData: data, history: props.history}));
    }
  }
};




export default connect((state, props) => {
  const newProps = {
    contest: state.contest
  };
  return newProps;
}, mapPropsToDispatch)(AttendContainer);
