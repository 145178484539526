import React from 'react';
import styled from 'styled-components';
import {
    IonGrid,
    IonItem,
} from '@ionic/react';


import ListHeaderElement from './ListHeaderElement';
import ListElement from './ListElement';


const StyledGrid = styled(IonGrid)`
    width: 100%;
`;

const StyledIonItem = styled(IonItem)`
    margin-left:0px;
    margin-right:0px;
    padding:0;
    --padding-top: 1    0px;
    --padding-start: 0;
    --inner-padding-end: 0;
    &>.item-native {
        padding-left:0;
    }
`;

export interface ListViewProps {
    content: {
        headers: Array<{
          content: string,
          size: string  
        }>,
        rows: Array<{
            cols: Array<React.ReactNode>,
            onClick?: Function
        }>
    }
}

const ListView: React.FC<ListViewProps> = props => {
    const { content } = props;
    const { headers, rows } = content;
    return (<StyledIonItem>
        <StyledGrid fixed={true}>
            <ListHeaderElement content={headers} />
            {rows.map((row, index) => {
                return (<ListElement 
                    key={`lekey${index}`} 
                    headers={headers} 
                    index={index} 
                    content={row} />);
            })}
        </StyledGrid>
    </StyledIonItem>)

};



export default ListView;